.o-media-btn {
    position: relative;
    width: 90px;
    height: 90px;
    color: #fff;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        border-radius: 50%;
        opacity: 0.6;
        transition: opacity $userActive $easeOut;
    }

    &:hover:before {
        opacity: 0.8;
        transition-duration: $userActive;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 55%;
        width: 72px;
        height: 72px;
        transform: translate(-50%, -50%);
    }

    &--sm {
        width: 66px;
        height: 66px;

        svg {
            width: 58px;
            height: 58px;
        }
    }

    &--teal-bg {
        &:before {
            background-color: $teal;
            opacity: 1;
        }
    }
}
