.c-404 {
    position: relative;
    height: calc(100vh - 48px);
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    &--no-nav {
        height: 100vh;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.45;
    }

    &__box {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 448px;
        border: 1px solid #fff;
        text-align: center;
        color: #fff;

        &:before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

    &__wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__label {
        position: relative;
        font-size: 1.3rem;
        letter-spacing: 0.165em;
        text-transform: uppercase;
        font-weight: 500;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 30px;
            height: 1px;
            background-color: currentColor;
            transform: translateX(-50%);
        }
    }

    &__content {
        .h-text--caption {
            color: #fff;

            + * {
                margin-top: 10px !important;
            }
        }
    }
}
