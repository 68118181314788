//====================
// timings
//====================

$userActive: 400ms;
$userInactive: 250ms;
$userOut: $userActive;
$userIn: $userInactive;

$scrollIn: 650ms;
$scrollInitialDelay: 300ms;
$scrollDelayInc: 150ms;

$scrollDelay: 120ms;
$siblingDelay: 140ms;
$scrollDuration: 1550ms;
