.c-dismissable-popup__container {

    padding: 2rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000000;

    &.default {
        background-color: $blue;
        * {
            color: White;
        }
        .c-dismissable-popup__countdown > div > div.c-dismissable-popup__ticker span span {
            background-color: rgba(8,17,48,0.5);
        }
    }

    &.coral_white {
        background-color: $red;
        * {
            color: White;
        }
        .c-dismissable-popup__countdown > div > div.c-dismissable-popup__ticker span span {
            background-color: rgba(255,255,255,0.2);
        }
    }

    &.yellow_blue {
        background-color: $yellow;
        * {
            color: $blue;
        }
        .c-dismissable-popup__countdown > div > div.c-dismissable-popup__ticker span span {
            background-color: rgba(95,86,0,0.5);
        }
    }

    .c-dismissable-popup__countdown {
        margin-bottom: 0;
        font-size: 3rem;
        font-family: $font-family;
        align-self: center;

        //
        display: flex;

        > div {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            > div {
                display: inline-block;
                &.c-dismissable-popup__ticker {
                    //
                    display: flex;
                    position: relative;
                    flex: 0 0 135px;

                    &:not(:first-child) {
                        margin-left: 15px;
                        &::before {
                            content: " : ";
                            //
                            position: absolute;
                            left: -14px;
                            top: -11px;
                            font-weight: 300;
                        }
                    }
                    span {
                        margin: 0;
                        //
                        display: flex;
                        flex-wrap: wrap;

                        span {
                            //
                            flex: 1 0 45px;
                            &:first-child {
                                margin-right: 5px;
                            }

                            padding: 1rem;
                            background-blend-mode: multiply;
                            border-radius: 5px;
                            width: 60px;
                            display: inline-block;
                            text-align: center;
                            line-height: 1;
                            font-weight: 300;
                        }
                    }
                }
                .smalltext {
                    font-size: 1.3rem;
                    text-align: center;
                    text-transform: uppercase;
                    //
                    flex: 1 1 100%;
                    margin-top: 5px;
                }
            }
        }

        @media(max-width: $xs) {
            font-size: 2.4rem;
            > div {
                > div {
                    &.c-dismissable-popup__ticker {
                        flex: 0 0 66px;
                        span {
                            span {
                                flex: 0 0 30px;
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }

        @media(min-width: $xs) {
            font-size: 4.8rem;
        }
    }

    .c-dismissable-popup__aside {
        margin-bottom: 0;
        display: flex;
        align-self: center;
        h3 {
            font-size: 4.8rem;
            @media(max-width: $xs) {
                font-size: 2.4rem;
            }
        }
    }

    .c-dismissable-popup__close {
        text-align: right;
        a {
            text-decoration: none;
            border: 2px solid White;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            font-weight: bold;
            line-height: 26px;
            text-align: center;
            vertical-align: baseline;
            display: inline-block;
        }
    }

    @media(min-width: $xxxs) {
        justify-content: center;

        text-align: center;
        .c-dismissable-popup__close,
        .c-dismissable-popup__aside h3,
        .c-dismissable-popup__countdown h3 {
            text-align: center;
            margin: 0 auto;
        }
        .c-dismissable-popup__close {
            min-height: 40px;
        }
        .c-dismissable-popup__aside,
        .c-dismissable-popup__countdown {
            margin-bottom: 1rem;
        }
    }

    @media(min-width: $lg) {
        .c-dismissable-popup__close {
            text-align: right;
            margin-left: auto;
            margin-right: 0;
        }
        .c-dismissable-popup__aside,
        .c-dismissable-popup__countdown {
            margin-bottom: 0;
        }
    }
}

@supports not (display: grid) {
    .c-dismissable-popup__container {
        display: flex;
        justify-content: space-evenly;

        .c-dismissable-popup__countdown {
            > div {
                > div {
                    span {
                        span {
                            flex: 1 0 45px;
                        }
                    }
                }
            }
        }

        .c-dismissable-popup__close {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
        @media(max-width: $sm) {
            flex-direction: column;
        }
    }
}

@supports (display: grid) {

    .c-dismissable-popup__container {
    
        display: grid;
    
        &.cols-1 {
            grid-template-columns: minmax(0, 1fr) minmax(0, 10fr) minmax(0, 1fr);
            grid-template-areas: "... countdown close";
        }

        .c-dismissable-popup__countdown {
            grid-area: countdown;            
        }

        .c-dismissable-popup__aside {
            grid-area: aside;
        }

        .c-dismissable-popup__close {
            grid-area: close;
        }

            grid-template-columns: none;
            grid-template-areas:
                "aside"
                "countdown";

            .c-dismissable-popup__close {
                position: absolute;
                top: 1rem;
                right: 1rem;
            }

        @media(min-width: $lg) {
            grid-template-columns: minmax(0, 0.5fr) minmax(0, 5fr) minmax(0, 0.5fr) minmax(0, 5.5fr) minmax(0, 0.5fr);
            grid-template-areas: "... countdown ... aside close";
        }
    
    }
}