.o-group-nav-btn {
    color: $grey-light;
    padding: 13px 0;
    transition: color $userInactive $easeOut;

    &:hover,
    &.is-active {
        color: $blue;
        transition-duration: $userActive;
    }

    &--white {
        color: #fff;
        transition: color $userInactive $easeOut,
            background-color $userInactive $easeOut;
        background-color: $blue;
        padding: 0 32px;

        &.is-active,
        &:hover {
            color: $blue;
            background-color: #fff;
            transition: color $userActive $easeOut,
                background-color $userActive $easeOut;
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
    }

    &__logo {
        color: $blue;

        &,
        svg {
            width: 48px;
            height: 48px;
        }
    }

    &--white &__logo {
        color: currentColor;
    }

    &__label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.3rem;
        letter-spacing: 0.16em;
    }

    &__icon {
        position: relative;
        left: -8px;
        transition: transform $userInactive $easeOut;

        &,
        svg {
            width: 48px;
            height: 48px;
        }

        svg {
            position: absolute;
            top: -1px;
            left: 0;
            transition: transform $userInactive $easeOut;
        }
    }

    &.is-active &__icon {
        transform: rotateX(180deg);
        transition: transform $userActive $easeOut;
    }

    &:focus &__icon {
        outline: 2px solid $blue;
    }

    &--white:focus &__icon {
        outline: 2px solid #fff;
    }

    &:focus:not(.focus-visible) &__icon {
        outline: none;
    }
}
