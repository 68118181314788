.c-scroll-anim {
    &--fade-up {
        transform: translateY(150px);
        transition: transform $scrollDuration $easeOutExpo;

        &.scroll-active {
            transform: none;
        }
    }
}
