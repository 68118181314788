.c-notification {
    &__wrap {
        background-color: $off-white;
        position: relative;
    }

    &__container {
        padding: $gutter;
        position: relative;
        transform: translateY(-100%);
        transition: transform $userActive $easeOut;

        @media (min-width: $sm) {
            display: flex;
            justify-content: space-between;
        }
    }

    p {
        color: $grey;
        font-size: 1.4rem;
        line-height: 1.8;

        @media (min-width: $sm) {
            flex: 1 1 60%;
        }

        a {
            color: $grey;
            text-underline-position: under;

            &:hover {
                color: $blue;
            }
        }
    }

    &__button-wrap {
        margin-left: 0;
        margin-top: 20px;

        @media (min-width: $sm) {
            margin-top: 0;
            margin-left: 100px;
            flex: 1 1 40%;
            text-align: right;
        }
    }

    &--warning &__wrap {
        background-color: $error-bg;
    }

    &--warning p {
        color: $error;

        a {
            color: $error;
        }
    }

    &--info &__wrap {
        background-color: $success-bg;
    }

    &--info p {
        color: $success;

        a {
            color: $success;
        }
    }

    &--stick-to-bottom &__container {
        transform: none;
    }

    &--stick-to-bottom &__wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 200;
    }

    &.queued &__wrap {
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height $userInactive $easeOut;
    }

    &.active &__wrap {
        height: auto;
        transition: height $userActive $easeOut;
    }

    &.active &__container {
        transform: none;
    }
}
