.c-latest-offers {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: $sm) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &__wrap {
        position: relative;

        &-fullheight {
            min-height: 100vh;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ccc;
        overflow: hidden;

        &--fixed {
            position: sticky;
            width: 100%;
            height: 100vh;
            z-index: -1;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.35;
        }
    }

    &__image {
        position: absolute;
        top: -150px;
        bottom: -150px;
        left: 0;
        right: 0;
        overflow: hidden;

        &-wrap {
            position: absolute;
            top: -150px;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__heading {
        position: relative;
        padding-bottom: 11px;
        color: #fff;
        font-size: 3.8rem;
        font-weight: 300;
        line-height: 1.3;

        + * {
            margin-top: 30px;

            @media (min-width: $sm) {
                margin-top: 50px;
            }
        }
    }

    &__title {
        position: relative;
        padding-bottom: 11px;
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 3rem;
        font-weight: 300;
        line-height: 1.3;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 60px;
            height: 1px;
            transform: translateX(-50%);
            background-color: currentColor;
        }

        + * {
            margin-top: 30px;

            @media (min-width: $sm) {
                margin-top: 50px;
            }
        }
    }

    &__filter {
        min-width: 320px;
        position: relative;
        .choices__inner {
            color: #fff;
        }

        + * {
            margin-top: 30px;

            @media (min-width: $sm) {
                margin-top: 50px;
            }
        }
    }

    &__container {
        display: flex;

        > * {
            margin-left: $gutter;
            margin-right: $gutter;
            width: calc(100% - #{$full-gutter});
        }

        + * {
            margin-top: 60px;
        }

        @media (min-width: $md) {
            .o-carousel__track {
                display: flex;

                > * {
                    margin-left: $gutter;
                    margin-right: $gutter;
                    flex: 1 1 25%;
                    flex-basis: calc(25% - #{$full-gutter});
                    width: calc(25% - #{$full-gutter});

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .o-carousel__dots {
            bottom: -35px;

            @media (min-width: $md) {
                display: none;
            }
        }

        .o-carousel__viewport {
            overflow: visible;
        }

        .o-carousel__slide {
            padding: 5px;
            transform: translateY(100%);
            flex: 1 0 100%;

            @media (min-width: $sm) {
                flex: 1 0 50%;
            }

            @media (min-width: $md) {
                flex: 0 1 25%;
            }

            &:nth-child(1) {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay;
            }

            &:nth-child(2) {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                    150ms;
            }

            &:nth-child(3) {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                    400ms;
            }

            &:nth-child(4) {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                    550ms;
            }

            @media (min-width: $md) {
                padding: 0;
            }

            .scroll-active & {
                transform: none;
            }
        }
    }

    &__button {
        transform: translateY(300px);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay + 700ms;
    }

    .scroll-active &__button {
        transform: none;
    }

    &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        width: 100%;
        overflow: hidden;
        max-width: 450px;

        @media (min-width: unit((450 + 30) / 16, em)) {
        }

        @media (min-width: $md) {
            max-width: 332px;
        }

        .o-carousel__slide & {
            height: 100%;
            margin: 0 auto;
        }

        &-container {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            padding: 20px;
            align-items: center;

            @media (min-width: $md) {
                align-items: flex-start;
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            position: relative;
            color: $grey-light;
            margin-bottom: 10px;
            text-align: center;

            @media (min-width: $md) {
                margin-bottom: 0;
                text-align: left;
            }

            &-wrap {
                position: relative;
                display: none;
                width: 20px;
                height: 28px;
                margin-bottom: 10px;

                @media (min-width: $md) {
                    display: block;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -9px;
                    left: 0;
                    width: 20px;
                    height: 1px;
                    background-color: currentColor;
                }
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 48px;
                height: 48px;
                transform: translate(-50%, -50%);
            }
        }

        &-label {
            margin-top: 5px;
            font-size: 1.3rem;
            font-weight: 500;
            letter-spacing: 0.16em;
            line-height: 1.25;
            text-transform: uppercase;

            @media (min-width: $md) {
                margin-left: 20px;
            }
        }

        &-text {
            font-size: 1.8rem;
            line-height: 1.25;
            font-weight: 300;
            text-align: center;
            width: 100%;

            @media (min-width: $xxxs) {
                font-size: 2.4rem;
            }

            @media (min-width: $md) {
                margin-top: 20px;
                font-size: 2rem;
                text-align: left;
            }

            @media (min-width: $lg) {
                font-size: 2.4rem;
            }
        }

        &-details {
            display: none;
            margin-top: 14px;

            @media (min-width: $xl) {
                display: block;
            }

            p {
                font-size: 1.6rem;
            }
        }

        &-button {
            margin-top: auto;
            padding-top: 15px;

            .o-btn {
                display: block;
            }
        }

        &-image {
            position: relative;
            overflow: hidden;
            margin: 0 auto;
            width: 100%;
            max-width: 33%;
            margin-top: 20px;

            @media (min-width: $md) {
                max-width: 100%;
                margin-top: auto;
                order: 1;
            }

            &:before {
                content: '';
                display: block;
                padding-bottom: 100%;
            }
        }

        &-image img {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100%;
            width: auto;
            max-width: none;
            transform: translate(-50%, 50%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay;

            @media (min-width: $md) {
                transform: translate(-50%, 50%);
            }
        }

        &-container {
            transform: translateX(-100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                100ms;
        }

        @media (min-width: $md) {
            &:nth-child(4n + 1) &-image img,
            &:nth-child(4n + 1) &-container {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay;
            }

            &:nth-child(4n + 2) &-image img,
            &:nth-child(4n + 2) &-container {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                    150ms;
            }

            &:nth-child(4n + 3) &-image img,
            &:nth-child(4n + 3) &-container {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                    400ms;
            }

            &:nth-child(4n) &-image img,
            &:nth-child(4n) &-container {
                transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                    550ms;
            }
        }

        .scroll-active &-image img {
            transform: translate(-50%, -50%);
        }

        .scroll-active &-container {
            transform: none;
        }
    }

    &--no-carousel &__container {
        flex-wrap: wrap;
    }

    &--no-carousel &__item {
        flex: 0 1 100%;
        margin-top: $full-gutter;

        &:nth-child(1) {
            margin-top: 0;
        }

        @media (min-width: $sm) {
            flex: 0 1 50%;
            flex-basis: calc(50% - #{$full-gutter});

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @media (min-width: $md) {
            max-width: 332px;
            flex: 0 1 25%;
            flex-basis: calc(25% - #{$full-gutter});

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                margin-top: 0;
            }
        }
    }
}

@supports (position: sticky) {
    .c-latest-offers {
        &__background--fixed + & {
            margin-top: -100vh;
        }
    }
}

@supports (object-fit: cover) {
    .c-latest-offers {
        &__item-image {
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translateY(100%);
            }
        }

        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }

        &__item.scroll-active &__item-image img {
            transform: none;
        }
    }
}
