ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

#{$typegraphyClass} {
    ul,
    ol {
        list-style: none;
        padding: 0;

        ul,
        ol {
            margin-left: 1.5em;
        }

        + * {
            margin-top: $largeSpace;
        }
    }

    li {
        line-height: $p-line-height;

        + * {
            margin-top: 4px;
        }
    }

    ul {
        margin-left: 1.2em;
    }

    ul li {
        position: relative;

        &:before {
            content: "";
            width: 8px;
            height: 8px;
            background-color: $teal;
            background-color: var(--theme);
            position: absolute;
            top: 0.7em;
            left: -0.85em;
            transform: translate(-50%, -50%);
        }
    }

    ol {
        counter-reset: orderedList;
    }

    ol li {
        position: relative;
        padding: 7px 0px 0px 5.5rem;
        line-height: 2;

        + li {
            margin-top: 0;
        }

        ol {
            margin-top: 7px;

            li:last-child {
                padding-bottom: 0;
            }
        }

        &:before {
            counter-increment: orderedList;
            content: counter(orderedList);
            display: inline-block;
            width: 30px;
            height: 30px;
            color: #fff;
            background-color: $yellow;
            border-radius: 50%;
            margin-right: 13px;
            font-family: $font-family-serif;
            font-size: 1.8rem;
            line-height: 1.8;
            text-align: center;
            margin-left: -4.5rem;
        }
    }
}
