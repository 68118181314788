.c-gallery {
    display: flex;
    flex-wrap: wrap;

    &__item {
        overflow: hidden;
        position: relative;

        @media (min-width: $xs) {
            height: 500px;
        }

        &--half {
            flex: 0 0 100%;

            @media (min-width: $sm) {
                flex: 0 0 50%;
            }

            &:before {
                content: "";
                display: block;
                padding-bottom: ratio(568, 380);

                @media (min-width: $xs) {
                    display: none;
                }
            }
        }

        &--full,
        &--thirds {
            flex: 0 0 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }

        &--thirds {
            height: auto;
            overflow: visible;

            @media (min-width: $md) {
                display: flex;
                height: 500px;
                overflow: hidden;
            }

            > * {
                position: relative;
                flex: 1 1 100%;

                @media (min-width: $xs) {
                    height: 500px;
                }

                &:not(.c-gallery__third):before {
                    content: "";
                    display: block;
                    padding-bottom: ratio(568, 380);

                    @media (min-width: $xs) {
                        display: none;
                    }
                }
            }
        }

        .c-square {
            width: 100%;
            max-width: 500px;
            max-height: 500px;
        }
    }

    &__third {
        display: flex;
        justify-content: center;
        flex: 0 0 500px;
        height: auto;

        @include theme-color(background-color);
    }
}

@supports (object-fit: cover) {
    .c-gallery {
        &__item img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: none;
            object-fit: cover;
        }
    }
}

@supports (display: grid) {
    .c-gallery {
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));

        &__item {
            &--half {
                grid-column: span 6;

                @media (min-width: $sm) {
                    grid-column: span 3;
                }
            }

            &--full,
            &--thirds {
                grid-column: span 6;
            }
        }
    }
}
