$listingctaBP: unit(700 / 16, em);
$listingctaBPMax: unit(699 / 16, em);

.c-listing-cta {
    display: flex;
    align-items: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 70px $gutter 50px;

    @media (min-width: $listingctaBP) {
        flex-direction: row;
        align-items: flex-start;
        padding: 140px 0;
    }

    @media (min-width: $md) {
        padding: 75px $gutter;

        &:first-child {
            padding-top: 150px;
        }

        &:last-child {
            padding-bottom: 140px;
        }
    }

    &__list {
        .c-introduction + & {
            margin-top: -60px;
        }
        @media(max-width: $xs-max) {
            .c-introduction + & {
                margin-top: -20px;
            }
        }
    }

    &:nth-child(even),
    &--flip {
        @media (min-width: $listingctaBP) {
            flex-direction: row-reverse;
        }
    }

    &--thin {
        padding-top: 55px;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 0;
        display: block;
        display: flex;
        flex-direction: column;

        @media (min-width: $md) {
            padding-top: 20px;
            padding-left: 110px;

            &:first-child,
            &:first-child + * {
                padding-top: 85px;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &--thin {
            display: block;
        }
    }

    &--thin:nth-child(even) {
        @media (min-width: $listingctaBP) {
            flex-direction: column;
        }
    }

    .c-introduction + &__list-halves &--thin,
    .c-signpost + &__list-halves &--thin {
        // display: flex;
        // flex-direction: column;
        @media (min-width: $md) {
            padding-top: 20px;

            &:first-child {
                padding-top: 20px;
            }
        }
    }

    &--thin:nth-child(even) {
        // display: flex;
        // flex-direction: column;

        @media (min-width: $md) {
            padding-right: 110px;
            padding-left: 0;
        }
    }

    @media all and (-ms-high-contrast: none) {
        &--thin:nth-child(even) {
            display: block;
        }
    }

    &__media {
        position: relative;
        margin-left: 15px;
        margin-right: 15px;
        width: 100%;
        max-width: 690px;
        z-index: 5;

        @media (min-width: $listingctaBP) {
            flex: 1 1 50%;
            width: 50%;
            margin-left: 15px;
            margin-right: 15px;
        }

        @media (min-width: $lg) {
            margin-left: 60px;
            margin-right: 60px;
        }

        + * {
            margin-top: 36px;

            @media (min-width: $listingctaBP) {
                margin-top: 0;
            }
        }
    }

    &:nth-child(even) &__media:before,
    &--flip &__media:before {
        @media (min-width: $listingctaBP) {
            right: auto;
            left: 25%;
        }
    }

    &--thin &__media {
        margin: 0;
        width: 100%;
        max-width: none;

        + * {
            margin-top: 27px;
        }
    }

    &__label {
        position: absolute;
        top: -20px;
        left: 20px;
        z-index: 1;

        @media (min-width: $listingctaBP) {
            top: -40px;
            left: 40px;
        }

        > * {
            transform: scale(1.2) translateY(100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay * 3;
        }
    }

    &.scroll-active &__label > * {
        transform: none;
    }

    &:nth-child(even) &__label,
    &--flip &__label {
        left: auto;
        right: 20px;

        @media (min-width: $listingctaBP) {
            right: 40px;
        }
    }

    &__image {
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }

        .o-icon-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
        }

        .o-carousel__slide {
            position: relative;
            overflow: hidden;
        }
    }

    &__image .o-carousel__dots,
    &__image .o-icon-btn {
        transform: translateY(48px);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2.5;
    }

    &--thin:nth-child(2n) &__image .o-carousel__dots,
    &--thin:nth-child(2n) &__image .o-icon-btn {
        @media (min-width: $sm) {
            transition: transform $scrollDuration $easeOutExpo #{$scrollDelay *
                2.5 + $siblingDelay};
        }
    }

    &.scroll-active &__image .o-carousel__dots,
    &.scroll-active &__image .o-icon-btn {
        transform: none;
    }

    &__image-wrap > * {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;
    }

    &--thin:nth-child(2n) &__image-wrap > * {
        @media (min-width: $sm) {
            transition: transform $scrollDuration $easeOutExpo $scrollDelay +
                $siblingDelay;
        }
    }

    &.scroll-active &__image-wrap > * {
        transform: none;
    }

    &--thin &__image {
        &:before {
            padding-bottom: ratio(568, 380);
        }
    }

    &__description {
        position: relative;
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @media (min-width: $listingctaBP) {
            flex: 1 1 50%;
            padding-left: 15px;
            padding-right: 15px;
        }

        @media (min-width: $md) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media (min-width: $lg) {
            padding-right: $col-1;
        }

        .o-btn__wrap {
            > * {
                position: relative;
                top: -15px;
                margin-top: 15px;
                width: calc(100% - 8px);

                @media (min-width: $md) {
                    width: auto;
                }
            }

            // so little luxuries next to book a room are the same size
            .o-btn + .o-btn {
                min-width: 230px;
            }
        }
    }

    &--thin &__description {
        flex: 1 1 100%;
        // display: flex;
    }

    &--thin &__description-wrap,
    &--thin &__description-wrap &__content-wrap {
        // display: flex;
        // flex-direction: column;

        > *:nth-last-child(2) {
            margin-bottom: 30px;
        }

        .o-btn__wrap {
            margin-top: auto;
        }
    }

    &:nth-child(even) &__description,
    &--flip &__description {
        > * {
            transform: translateX(120%);
        }

        @media (min-width: $lg) {
            padding-right: 30px;
            padding-left: $col-1;
        }
    }

    &.scroll-active &__description > * {
        transform: none;
    }

    &__description-wrap {
        overflow: hidden;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        > * {
            transform: translateX(-100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2;
        }
    }

    &--thin &__description-wrap > * {
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 3.25;
    }

    &--thin:nth-child(2n) &__description-wrap > * {
        @media (min-width: $sm) {
            transition: transform $scrollDuration $easeOutExpo $scrollDelay *
                3.25 + $siblingDelay;
        }
    }

    &.scroll-active &__description-wrap > * {
        transform: none;
    }

    &--thin:nth-child(even) &__description {
        @media (min-width: $lg) {
            padding: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }

    &--thin &__description {
        padding: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &:after {
            display: none;
        }
    }

    &__decoration-line {
        display: none;
        position: absolute;
        right: -15px;
        top: 0;
        width: 8px;
        height: 510px;
        overflow: hidden;

        @media (min-width: $md) {
            display: block;
        }
    }

    &:nth-child(even) &__decoration-line,
    &--flip &__decoration-line {
        right: auto;
        left: -15px;
    }

    &__decoration-box {
        position: absolute;
        top: -75px;
        right: 50%;
        width: 100vw;
        height: calc(100% + 50px);

        @media (max-width: $listingctaBPMax) {
            transform: translateX(50%) !important;
        }

        @media (min-width: $listingctaBP) {
            top: -165px;
            right: 25%;
            width: 460px;
            height: 460px;
            transform: none;
            overflow: hidden;
        }

        @media (min-width: $md) {
            width: 610px;
            height: 610px;
            top: -150px;
        }

        &:before,
        &--circle-pattern svg {
            transform: translateY(100%);
            transition: transform $scrollDuration $easeOutExpo;
        }

        &--circle-pattern {
            svg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0.15;
                color: #000;
                transform: scaleX(-1) translateY(100%);
            }
        }
    }

    &.scroll-active &__decoration-box:before,
    &.scroll-active &__decoration-box--circle-pattern svg {
        transform: none;
    }

    &.scroll-active &__decoration-box--circle-pattern svg {
        transform: scaleX(-1);
    }

    &:nth-child(even) &__decoration-box--circle-pattern svg,
    &--flip &__decoration-box--circle-pattern svg {
        transform: translateY(100%);
    }

    &:nth-child(even).scroll-active &__decoration-box--circle-pattern svg,
    &--flip.scroll-active &__decoration-box--circle-pattern svg {
        transform: none;
    }

    &__decoration-line,
    &__decoration-box {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $grey;

            @include theme-color(background-color);
        }
    }

    &:nth-child(even) &__decoration-line:before,
    &:nth-child(even) &__decoration-box:before,
    &__list.o-theme--secondary &__decoration-line:before,
    &__list.o-theme--secondary &__decoration-box:before {
        @include theme-color(background-color, 1);
    }

    &__decoration-line:before {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2;
    }

    &--thin:nth-child(2n) &__decoration-line:before {
        @media (min-width: $sm) {
            transition-delay: $scrollDelay * 2 + $siblingDelay;
        }
    }

    &.scroll-active &__decoration-line:before {
        transform: none;
    }

    &:nth-child(even) &__decoration-box,
    &--flip &__decoration-box {
        @media (min-width: $listingctaBP) {
            right: auto;
            left: 25%;
        }
    }

    &--thin &__decoration-line {
        left: -125px;
        height: 100%;
        display: none;

        @media (min-width: $md) {
            display: block;
        }
    }

    &--thin:nth-child(even) &__decoration-line {
        left: auto;
        right: -125px;
    }

    &__list-halves {
        padding-top: 15px;
        padding-bottom: 100px;
        display: flex;
        flex-wrap: wrap;

        .c-introduction + & {
            margin-top: -60px;
        }

        @media(max-width: $xs-max) {
            .c-introduction + & {
                margin-top: -20px;
            }
        }

        > * {
            margin-left: $gutter;
            margin-right: $gutter;
            margin-top: $full-gutter;
            flex: 1 1 100%;
            flex-basis: calc(100% - #{$full-gutter});
            width: calc(100% - #{$full-gutter});

            @media (min-width: $sm) {
                flex: 1 1 50%;
                flex-basis: calc(50% - 150px);
                width: calc(50% - #{$full-gutter});
                max-width: calc(50% - #{$full-gutter});
            }

            &:first-child {
                margin-top: 0;
            }

            &:nth-child(2) {
                @media (min-width: $sm) {
                    margin-top: 0;
                    // flex-grow: 1;
                    // display: flex;
                    // flex-direction: column;
                }
            }

            &.l-no-gutter {
                margin-left: 0;
                margin-right: 0;
                flex-basis: 100%;
                width: 100%;

                @media (min-width: $sm) {
                    flex-basis: 50%;
                    width: 50%;
                }
            }
        }
    }

    &__list-title {
        padding-top: 75px;
        margin-bottom: -20px;

        .c-introduction + & {
            padding-top: 0;
        }
    }

    &__list-title + &__list-halves {
        padding-top: 0;
    }

    &__filters {
        display: flex;
        justify-content: center;

        > * {
            width: 400px;
            flex: 1 1 400px;
            margin-top: 0;
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    &__filters-container {
        padding-top: 80px;
        padding-left: 110px;
        padding-right: 110px;

        h2 {
            text-align: center;

            &:after {
                left: 50%;
                width: 30px;
                transform: translateX(-50%);
            }
        }
    }

    &__message {
        p {
            padding-top: 50px;
            text-align: center;
        }
    }

    &__content-wrap {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 0;
    }
}

@supports (display: grid) {
    .c-listing-cta {
        &__list-halves {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: $full-gutter;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $sm) {
                grid-template-columns: 1fr 1fr;
            }

            > * {
                margin-left: 0;
                margin-right: 0;
                margin-top: 0;
                width: 100%;
                max-width: none;

                &.l-no-gutter {
                    margin-left: -$gutter;
                    margin-right: -$gutter;
                    width: calc(100% + #{$full-gutter});
                }
            }
        }
    }
}

@supports (object-fit: cover) {
    .c-listing-cta {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
