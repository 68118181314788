.c-book {
    position: relative;

    &__wrap {
        position: absolute;
        top: 100%;
        right: 0;
        height: 0;
        overflow: hidden;
        width: 100%;
        will-change: height;
        transition: height $userInactive $easeOut;
        z-index: $zindex-modal;

        @media (min-width: $navMobileBreakpoint) {
            width: 224px;
        }
    }

    &.is-active &__wrap {
        height: auto;
        transition: height $userActive * 2 $easeOutExpo;
    }

    &__container {
        background-color: #fff;

        @media (min-width: $navMobileBreakpoint) {
            border-top: 4px solid $teal;
        }
    }

    &__list {
        border: 1px solid $grey-light;
        border-top: 0;
    }

    &__sub-item,
    &__item {
        display: block;
        width: 100%;
        font-size: 1.3rem;
        letter-spacing: 0.16em;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        transition: color $userInactive $easeOut,
            background-color $userInactive $easeOut;
        color: $grey;
        cursor: pointer;

        &:hover,
        &:focus {
            transition: color $userActive $easeOut,
                background-color $userActive $easeOut;
        }

        &:hover {
            text-decoration: none;
            color: $grey;
        }

        &:focus {
            outline: none;
            background-color: $blue-highlight;
        }

        &:focus:not(.focus-visible) {
            background-color: transparent;
        }
    }

    &__item,
    &__accordion {
        + * {
            border-top: 1px solid $grey-light;
        }
    }

    &__item {
        &-wrap {
            display: flex;
            align-items: center;
        }
    }

    &__sub-item {
        padding: 13px 16px 13px 48px;
        transition: color $userInactive $easeOut,
            background-color $userInactive $easeOut;

        &:hover {
            background-color: $blue-highlight;
        }
    }

    &__accordion.is-active &__item &__label,
    &__item:hover &__label,
    &__item:focus &__label {
        color: $blue;
    }

    &__item:focus:not(.focus-visible) &__label {
        color: $grey;
    }

    &__icon {
        color: $grey-light;
    }

    &__accordion-icon,
    &__icon {
        position: relative;
        width: 48px;
        height: 48px;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 48px;
            height: 48px;
            transform: translate(-50%, -50%);
        }
    }

    &__accordion {
        &-container {
            overflow: hidden;
            height: 0;
            will-change: height;
            transition: height $userInactive $easeOut;
            border-top: 0;
        }

        &.is-active &-container {
            height: auto;
            transition: height $userActive $easeOut;
        }

        &-icon {
            margin-left: auto;
            transition: transform $userInactive $easeOut;

            svg {
                width: 48px;
                height: 48px;
                transition: transform $userInactive $easeOut;
            }
        }

        &.is-active &-icon {
            transform: rotateX(180deg);
            transition-duration: $userActive;
        }
    }

    &__item:hover &__accordion-icon svg {
        transform: translate(-50%, calc(-50% + 5px));
        transition-duration: $userActive;
    }
}
