// inherits from .c-gift-cta

.c-newsletter-cta {
    position: relative;

    .o-square-wave__background {
        background-color: $yellow;

        .o-theme--beach-club & {
            background-color: $beach-club-coral;
        }
    }

    .o-theme--beach-club & {
        h2 {
            letter-spacing: -0.06em;
            font-family: Fascino, 'Times New Roman', Times, serif !important;
            color: $beach-club-coral;
            font-weight: 600;
            line-height: 2 !important;
            padding-bottom: 0;

            @media (min-width: $md) {
                font-size: 4.6rem !important;
            }

            &:after {
                display: none;
            }
        }
    }

    .o-square-wave__image {
        width: 60%;
    }

    &__gdpr {
        margin-top: 24px;
        font-size: 1.4rem;
        color: $grey-light;

        a {
            color: currentColor;

            &:hover {
                color: $teal;
            }
        }
    }

    &__errors {
        > * {
            &:last-child {
                margin-bottom: 30px;
            }
        }
    }

    &__square {
        padding-bottom: 60px;
    }

    &__anchor {
        position: absolute;
        top: 30%;
        
        @media (min-width: $sm) {
            top: -120px;
        }
    }

    + .c-mix-content-aside {
        padding-top: 0;
    }
}
