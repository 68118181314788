// colours
//===============
.h-background {
    &--blue {
        background-color: $blue !important;
    }

    &--blue-highlight {
        background-color: $blue-highlight !important;
    }

    &--teal {
        background-color: $teal !important;
    }

    &--yellow {
        background-color: $yellow !important;
    }

    &--dark-teal {
        background-color: $dark-teal !important;
    }

    &--red {
        background-color: $red !important;
    }

    &--room-gold {
        background-color: $room-gold !important;
    }

    &--spa-blue {
        background-color: $spa-blue !important;
    }

    &--spa-blue-light {
        background-color: $spa-blue-light !important;
    }

    &--eat-red {
        background-color: $eat-red !important;
    }

    &--eat-red-light {
        background-color: $eat-red-light !important;
    }

    &--wedding-violet {
        background-color: $wedding-violet !important;
    }

    &--wedding-violet-light {
        background-color: $wedding-violet-light !important;
    }

    &--meeting-blue {
        background-color: $meeting-blue !important;
    }

    &--meeting-blue-light {
        background-color: $meeting-blue-light !important;
    }

    &--see-green {
        background-color: $see-green !important;
    }

    &--see-green-light {
        background-color: $see-green-light !important;
    }

    &--grey {
        background-color: $grey !important;
    }

    &--grey-dark {
        background-color: $grey-dark !important;
    }

    &--grey-light {
        background-color: $grey-light !important;
    }

    &--white {
        background-color: #fff !important;
    }

    &--black {
        background-color: $black !important;
    }

    &--transparent {
        background-color: transparent !important;
    }
}
