a {
    background-color: transparent;
    color: $link-color;
    text-decoration: $link-decoration;
    -webkit-text-decoration-skip: objects;
    transition: color $userInactive $easeOut;

    &:hover {
        color: $link-hover;
        text-decoration: $link-hover-decoration;
        transition: color $userActive $easeOut;
    }

    &:focus {
        outline: 2px solid $yellow;
    }
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
