.o-more-info {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid $blue;
    background-color: $blue;
    color: #fff;
    transition: color $userInactive $easeOut,
        background-color $userInactive $easeOut;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 56px;
        height: 56px;
        transform: translate(-50%, -50%);
    }

    &:hover {
        color: $blue;
        background-color: #fff;
        transition: color $userActive $easeOut,
            background-color $userActive $easeOut;
    }
}
