.c-hero {
    position: relative;
    overflow: hidden;

    &__sizer {
        &:before {
            content: "";
            display: block;
            padding-bottom: ratio(16, 8);

            @media (min-width: $md) {
                padding-bottom: ratio(1440, 420);
            }
        }
    }

    &--large &__sizer {
        &:before {
            @media (min-width: $md) {
                padding-bottom: ratio(1440, 520);
            }
        }
    }

    &--logo &__sizer {
        &:before {
            @media (min-width: $md) {
                padding-bottom: ratio(1440, 320);
            }
        }
    }

    &__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transform: translate3d(0, -100%, 0);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;

        &-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            > * {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                transform: translate(-50%, -50%);
            }

            > a {
                height: 100%;
                left: 0;
                top: 0;
                transform: none;
            }
        }
    }

    &.scroll-active &__media {
        transform: none;
    }

    &__content {
        position: relative;

        &-wrap {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 48px;
            width: 100%;
            overflow: hidden;
        }

        .o-icon-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate3d(0, 100%, 0);
            transition: transform $scrollDuration $easeOutExpo 1025ms;
        }
    }

    &.scroll-active &__content .o-icon-btn {
        transform: none;
    }

    ~ * {
        background-color: $body-background-color;
        z-index: 1;
    }

    &__event-label {
        overflow: hidden;
        transform: translateY(50%);

        > * {
            transform: translate3d(0, 100%, 0);
            transition: $scrollDuration $easeOutExpo $scrollDelay * 3;
        }
    }

    &.scroll-active &__event-label > * {
        transform: none;
    }

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        transform: translate(-50%, -50%);

        svg {
            width: 240px;
            height: 62px;
        }
    }
}


@supports (object-fit: cover) {
    .c-hero {
        &__media-wrap > *:not(a), &__media-wrap > a > *{
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: none;
            object-fit: cover;
        }
    }
}
