.c-signpost {
    position: relative;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ccc;
        overflow: hidden;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;
    }

    &.scroll-active &__background {
        transform: none;
    }

    &__image {
        position: absolute;
        top: -150px;
        bottom: -150px;
        left: 0;
        right: 0;
        overflow: hidden;

        &-wrap {
            position: absolute;
            top: -150px;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__container {
        position: relative;
        z-index: 1;
        padding-top: 113px;
        padding-bottom: 130px;
        overflow: hidden;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;

        .o-icon-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateY(100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay * 4;
        }
    }

    &.scroll-active &__container,
    &.scroll-active &__container .o-icon-btn {
        transform: none;
    }

    &__content {
        position: relative;
        width: 100%;
        padding-top: 26px;

        @media (min-width: $sm) {
            padding-left: 60px;
            padding-top: 0;
        }

        @media (min-width: $md) {
            margin-left: calc(#{$col-1} + #{$gutter});
            width: $col-8;
        }

        @media (min-width: $lg) {
            width: $col-6;
        }

        &-wrap {
            padding: 30px 20px 50px;
            background-color: #fff;
            overflow: hidden;

            @media (min-width: $sm) {
                padding: 50px;
            }

            > * {
                transform: translateX(-100%);
                transition: transform $scrollDuration $easeOutExpo $scrollDelay *
                    2;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 13px;
            left: 0;
            width: 100%;
            height: 13px;

            @include theme-color(background-color);

            @media (min-width: $sm) {
                width: 60px;
                top: 0;
                height: 100%;
            }
        }
    }

    &.scroll-active &__content-wrap > * {
        transform: none;
    }
}

@supports (object-fit: cover) {
    .c-signpost {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
