.c-footer {
    &__wrap {
        margin-top: -50px;
        padding-top: 50px;
        background-color: $off-white;
    }

    &__top {
        padding-bottom: 40px;
        background-color: transparent;

        @media (min-width: $sm) {
            padding-top: 75px;
            padding-bottom: 115px;
        }
    }

    &__top-nav {
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;
            justify-content: space-around;

            @media (min-width: $sm) {
                flex-direction: row;
            }
        }

        li + li {
            margin-top: 40px;

            @media (min-width: $sm) {
                margin-top: 0;
            }
        }

        &-icon {
            position: relative;
            width: 86px;
            height: 86px;
            border-radius: 50%;
            background-color: $teal;
            color: #fff;

            + * {
                margin-top: 21px;
            }

            svg {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 2.4rem;
            font-weight: 300;
            color: $grey;
            position: relative;
            padding-bottom: 11px;
            text-decoration: none;
            transition: color $userInactive $easeOut;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 30px;
                height: 1px;
                transform: translateX(-50%);
                background-color: currentColor;
                transition: width $userInactive $easeOut;
            }

            &:hover {
                color: $blue;
            }

            &:hover:after {
                width: 60px;
            }
        }
    }

    &__bottom {
        padding-top: 80px;
        padding-bottom: 65px;
        background-color: $blue;
    }

    &--thin &__bottom {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__bottom-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &__bottom-nav {
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            padding-left: $gutter;
            padding-right: $gutter;
            text-align: center;
            flex-direction: column;

            @media (min-width: $md) {
                flex-direction: row;
            }
        }

        li + li {
            margin-top: 16px;

            @media (min-width: $md) {
                margin-top: 0;
            }
        }

        a {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            text-transform: uppercase;
            font-weight: 500;
            text-decoration: none;
            letter-spacing: 0.16em;
            color: #fff;
            padding: 0 10px;

            @media (min-width: $sm) {
                font-size: 1.3rem;
            }

            @media (min-width: $xl) {
                padding: 0 16px;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 50%;
                width: 30px;
                height: 1px;
                background-color: currentColor;
                transform: translateX(-50%) scaleX(0);
                opacity: 0;
                transition: opacity $userInactive $easeOut,
                    transform $userInactive $easeOut;
            }

            &:hover:after,
            &:focus:after,
            &.is-active:after {
                opacity: 1;
                transition: opacity $userActive $easeOut,
                    transform $userActive $easeOut;
                transform: translateX(-50%) scaleX(1);
            }

            &:focus {
                color: #fff;
            }
        }
    }

    &__escape {
        margin-top: 40px;
        color: #fff;
        font-size: 1.4rem;

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__contact-container {
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        @media (min-width: $md) {
            flex-direction: row;
            align-items: stretch;
            margin-bottom: 42px;
        }

        > * + * {
            margin-top: 30px;

            @media (min-width: $md) {
                margin-left: 30px;
                margin-top: 0;
            }
        }
    }

    &__logo {
        display: block;
        position: relative;
        color: #fff;
        padding-bottom: 11px;
        transition: color $userInactive $easeOut;

        @media (min-width: $md) {
            align-self: flex-start;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 15px;
            left: 50%;
            width: 30px;
            height: 1px;
            transform: translateX(-50%);
            background-color: currentColor;
            transition: width $userInactive $easeOut;
        }

        &:hover {
            color: #fff;
        }

        &:hover:after {
            width: 60px;
        }

        svg {
            width: 48px;
            height: 48px;
        }

        + * {
            margin-top: 50px;

            @media (min-width: $md) {
                margin-top: 0;
            }
        }
    }

    &__contact-column {
        display: flex;
        flex-direction: column;
        text-align: center;

        @media (min-width: $xs) {
            width: 310px;
        }

        @media (min-width: $md) {
            text-align: left;
            justify-content: space-between;
            flex: 1 1 208px;
        }

        &:first-child,
        &:last-child {
            @media (min-width: $md) {
                flex: 1 1 87px;
            }
        }

        &--wide {
            @media (min-width: $md) {
                flex: 1 1 328px;
            }
        }

        p {
            font-size: 1.4rem;
            line-height: 1.6;
        }

        p + p {
            margin-top: 30px;

            @media (min-width: $md) {
                margin-top: 20px;
            }
        }

        span {
            font-weight: 500;
        }
    }

    &__awards {
        flex: 0 0 auto;
        margin-top: 80px;

        @media (min-width: $sm) {
            flex: 0 0 auto;
        }

        @media (min-width: $md) {
            flex: 0 0 25%;
            margin-top: 0;
        }

        img {
            display: block;
            margin: 0 auto;

            @media (min-width: $md) {
                display: block;
                margin: 0 auto;
            }
        }
    }
}
