.c-simple-list {
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;

    &__wrap {
        background-color: #fff;

        @include theme-color(background-color);
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        &-image {
            position: relative;
            height: 0;
            padding-bottom: ratio(568px, 380px);

            &-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;

                > img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    height: auto;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &-title {
            font-size: 2.4rem;
            font-weight: 300;
            color: $grey;
        }

        .o-theme--rooms &-title,
        .o-theme--rooms &-text,
        .o-theme--spa &-title,
        .o-theme--spa &-text,
        .o-theme--eat &-title,
        .o-theme--eat &-text,
        .o-theme--wedding &-title,
        .o-theme--wedding &-text,
        .o-theme--meeting &-title,
        .o-theme--meeting &-text,
        .o-theme--see &-title,
        .o-theme--see &-text {
            color: #fff;
        }

        > * + * {
            margin-top: 25px;
        }

        &-title + &-text {
            margin-top: 20px;
        }

        &-button .o-btn {
            margin-top: 0;
            min-width: 140px;
        }
    }

    &--line-deco.l-thirds &__item {
        @media (min-width: unit(1504 / 16, em)) {
            min-height: 538px;
        }
    }

    &--line-deco.l-quarters &__item {
        @media (min-width: unit(1504 / 16, em)) {
            min-height: 480px;
        }
    }

    .o-list--inline {
        margin-left: -8px;
    }

    &__deco {
        display: none;
    }

    &--line-deco &__deco {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 292px;
            background-color: $blue;
            z-index: 2;
            display: none;

            @media (min-width: unit(1504 / 16, em)) {
                display: block;
            }
        }

        &:before {
            top: 25px;
            left: -60px;
        }

        &:after {
            bottom: 270px;
            right: -60px;
        }
    }

    &--line-deco.l-thirds &__deco {
        &:before,
        &:after {
            height: 292px;
        }
    }

    &--line-deco.l-quarters &__deco {
        &:before,
        &:after {
            height: 214px;
        }

        &:after {
            bottom: 300px;
        }
    }
}

@supports (object-fit: cover) {
    .c-simple-list {
        &__item-image-wrap {
            img {
                top: 0;
                left: 0;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
