.o-book-btn {
    display: block;
    background-color: $teal;
    color: #fff;
    width: 100%;
    font-size: 1.3rem;
    letter-spacing: 0.16em;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #fff;
        text-decoration: none;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 20px 17px;
    }

    &__icon {
        position: relative;
        width: 12px;
        height: 12px;
        margin-left: 10px;
        transition: transform $userInactive $easeOut;

        svg {
            position: absolute;
            top: 50%;
            left: -17px;
            width: 48px;
            height: 48px;
            transform: translateY(-50%);
            transition: transform $userInactive $easeOut;
        }
    }

    &:hover &__icon {
        @media (min-width: $md) {
            transform: translateY(5px);
            transition-duration: $userActive;
        }
    }

    .c-book.is-active & &__icon svg {
        transform: translateY(-50%) rotateX(180deg);
        transition-duration: $userActive;
    }
}
