.o-form {
    label {
        display: inline-block;
        font-size: 1.3rem;
        letter-spacing: 0.16em;
        line-height: 1.6;
        font-weight: 500;
        text-transform: uppercase;

        + * {
            margin-top: 10px;
        }
    }

    // Make multiple select elements height not fixed
    select[multiple],
    select[size] {
        height: auto;
    }

    select,
    .radio input,
    .checkbox input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    select::-ms-expand {
        display: none;
    }

    // Search inputs in iOS
    //
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    input[type="search"] {
        -webkit-appearance: none;
    }

    @media(max-width: $xs-max) {
        label[for=preferred_date],
        label[for=date_in_mind] {
            position: relative;
            top: 0;
            margin-top: -40px;
        }
    }

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"] {
        display: block;
        width: 100%;
        padding: 19px 16px 5px;
        color: $text-color;
        border: 1px solid $grey-light;
        background-color: #fff;
        box-shadow: none;
        background-image: none;
        font-family: $font-family;
        transition: background-color $userInactive $easeOut,
            border-color $userInactive $easeOut;
        font-size: 1.4rem;
        line-height: 1.6;

        &[disabled],
        &[readonly] {
            border-color: rgba($grey-light, 0.5);
            cursor: not-allowed;
        }

        &:focus {
            transition-duration: $userActive;
            border-color: $blue;
        }
    }

    select {
        padding: 12px 16px;
        border-radius: 0;
        background-color: transparent;
        border: 1px solid $grey-light;
        color: $text-color;
        transition: background-color $userInactive $easeOut,
            border-color $userInactive $easeOut;

        &:focus {
            transition-duration: $userActive;
            border-color: $blue;
        }
    }

    textarea {
        min-height: 160px;
        min-width: 250px;
        resize: none;
    }

    &__group {
        position: relative;

        + * {
            margin-top: 30px;
        }
    }

    &__hover-label {
        position: relative;
        overflow: hidden;

        label {
            position: absolute;
            top: 14px;
            left: 16px;
            transition: transform $userInactive $easeOut,
                color $userInactive $easeOut;
            transform-origin: left center;
            white-space: nowrap;
            color: $grey-light;

            + * {
                margin-top: 0;
            }
        }

        .has-focus + label {
            transition: transform $userActive $easeOut,
                color $userActive $easeOut;
            transform: translateY(-50%) scale(0.85);
        }

        input:focus + label {
            color: $blue;
        }

        &--icon label {
            left: 48px;
        }

        &--icon input {
            padding-left: 48px !important;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 48px;
        color: $grey-light;
        transition: color $userInactive $easeOut;
        pointer-events: none;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 48px;
            height: 48px;
            transform: translate(-50%, -50%);
        }
    }

    input:focus ~ &__icon {
        color: $blue;
        transition-duration: $userActive;
    }

    &__group.has-error {
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"] {
            border-color: $error;
        }
    }

    &__group.has-error &__icon {
        color: $error;
    }

    &__group.has-error &__hover-label label {
        color: $error;
    }

    &__list {
        margin-left: -15px;
        margin-right: 0;
        width: calc(100% + 30px);

        @media (min-width: unit(880 / 16, em)) {
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            margin-left: 15px;
            margin-right: 15px;
            padding: 5px 0;
        }
    }

    &__message {
        will-change: height;
        transition: height $userInactive $easeOut;
        height: 0;
        overflow: hidden;

        &-wrap {
            margin-top: 10px;
            position: relative;
            padding: 10px 32px 10px 10px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: currentColor;
                opacity: 0.1;
            }
        }

        span {
            display: block;
            position: relative;
            z-index: 1;
        }

        &-icon {
            position: absolute;
            right: 6px;
            top: 50%;
            width: 24px;
            height: 24px;
            opacity: 0;
            transition: opacity $userInactive $easeOut;
            transform: translateY(-50%);
        }
    }

    &__group.has-error &__message,
    &__group.has-success &__message {
        height: auto;
        transition-duration: $userActive;
    }

    &__group.has-error &__message {
        color: $error;
    }

    &__group.has-success &__message {
        color: $success;

        span {
            color: $text-color;
        }
    }

    &__group.has-error &__message-icon--error,
    &__group.has-success &__message-icon--success {
        opacity: 1;
        transition-duration: $userActive;
    }

    &__help-msg {
        font-size: 1.4rem;
        margin-top: 8px;
        color: $grey-light;
    }

    &__title {
        margin-bottom: 7px;
    }
}
