.choices {
    position: relative;
    font-size: 1.3rem;
    min-width: 160px;

    &:focus {
        outline: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.is-disabled {
        .choices__inner,
        .choices__input {
            cursor: not-allowed;
            user-select: none;
        }

        .choices__item {
            cursor: not-allowed;
        }
    }

    &__inner {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        background-color: transparent;
        padding: 17px 16px 16px;
        line-height: 1;
        border: 1px solid $grey-light;
        overflow: hidden;

        .is-focused &,
        .is-open & {
            border-color: $blue;
        }

        .is-open & {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .is-flipped.is-open & {
            border-radius: 0 0 6px 6px;
        }
    }

    .o-form--white &__inner {
        border-color: #fff;
    }

    &__item {
        cursor: default;

        &--selectable {
            cursor: pointer;
        }

        &--disabled {
            cursor: not-allowed;
            user-select: none;
            opacity: 0.5;
        }
    }

    &__list {
        margin: 0;
        padding-left: 0;
        list-style: none;

        &--single {
            display: inline-block;
            padding-right: 12px;
            width: 100%;
        }

        &--multiple {
            display: inline;
        }

        &--dropdown {
            display: none;
            z-index: 10;
            position: absolute;
            width: 100%;
            background-color: transparent;
            border: 1px solid $blue;
            border-top: 0;
            top: calc(100% - 29px);
            padding-top: 28px;
            margin-top: -1px;
            overflow: hidden;
            word-break: break-all;

            .o-form--white & {
                border-color: #fff;
            }

            &.is-active {
                display: block;
            }

            .is-flipped & {
                top: auto;
                bottom: calc(100% - 32px);
                margin-top: 0;
                margin-bottom: -1px;
                padding-top: 0;
                padding-bottom: 29px;
                border-top: 1px solid $blue;
                border-bottom: 0;
            }

            .o-form--white .is-flipped & {
                border-color: #fff;
            }
        }
    }

    &__list--single &__item {
        width: 100%;
        white-space: nowrap;
    }

    &__list--multiple &__item {
        display: inline-block;
        vertical-align: middle;
        padding: 4px 10px;
        font-weight: 500;
        margin-right: 4px;
        margin-bottom: 4px;
        background-color: $blue-highlight;
        color: $text-color;
        word-break: break-all;

        &[data-deletable] {
            padding-right: 5px;
        }

        &.is-highlighted {
            background-color: darken($teal, 5%);
            border: 2px solid darken($teal, 10%);
        }

        .is-disabled & {
            background-color: darken($grey-lighter, 25%);
            border: 2px solid darken($grey-lighter, 35%);
        }
    }

    &__list--dropdown &__list {
        position: relative;
        max-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
        background-color: #fff;
    }

    .o-form--white &__list--dropdown &__list {
        background-color: $teal;

        @include theme-color(background-color);
    }

    .is-flipped &__list--dropdown &__list {
        border-top: 0;
    }

    &__list--dropdown &__item {
        position: relative;
        padding: 13px 16px;
        line-height: 1.3;
        font-size: $base-font-size;
    }

    &__list--dropdown &__item--selectable.is-highlighted {
        background-color: $blue-highlight;
    }

    .o-form--white &__list--dropdown &__item--selectable.is-highlighted {
        background-color: #fff;
        color: $teal;

        @include theme-color(color);
    }

    &__heading {
        font-weight: 600;
        font-size: $base-font-size;
        padding: 10px;
        border-bottom: 2px solid $grey-lighter;
        color: lighten(#333, 30%);
    }

    .o-form & &__input--cloned {
        display: inline-block;
        width: auto;
        padding: 0;
        border: 0;
    }

    &__button {
        text-indent: -9999px;
        -webkit-appearance: none;
        appearance: none;
        border: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .is-open &__button {
        transform: translateY(-50%) rotateX(-180deg);
        transition-duration: $userActive;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 0;
        width: 48px;
        height: 48px;
        transform: translateY(-50%);
        color: $grey-light;
        transition: color $userInactive $easeOut;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .is-open &,
        .is-focused & {
            color: $blue;
            transition-duration: $userActive;
        }
    }

    .o-form--white &__icon {
        color: #fff;
    }

    &[data-type*="select-one"] {
        cursor: pointer;
    }

    &[data-type*="select-one"] &__input {
        display: block;
        width: 100%;
        padding: 10px;
        background-color: #fff;
        margin: 0;
    }

    &[data-type*="select-one"] &__button {
        padding: 0;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        margin-right: 25px;
        height: 20px;
        width: 20px;
        border-radius: 10em;
        opacity: 0.5;

        &:hover,
        &:focus {
            opacity: 1;
        }

        &:focus {
            box-shadow: 0 0 0 2px $yellow;
        }
    }

    &[data-type*="select-one"].is-open:after {
        border-color: transparent transparent $text-color transparent;
        margin-top: -7.5px;
    }

    &[data-type*="select-multiple"] &__inner,
    &[data-type*="text"] &__inner {
        cursor: text;
        padding: 13px 16px 8px;
        height: 48px;
    }

    &[data-type*="select-multiple"] &__button,
    &[data-type*="text"] &__button {
        position: relative;
        display: inline-block;
        margin: 0 -4px 0 8px;
        padding-left: 16px;
        border-left: 2px solid darken($teal, 10%);
        // background-image: url($choices-button-icon-path+"/cross.svg");
        background-size: 8px;
        width: 8px;
        line-height: 1;
        opacity: 0.75;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}
