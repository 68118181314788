.c-group-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 48px);
    overflow: hidden;
    color: $text-color;
    pointer-events: none;

    @media (min-width: $navMobileBreakpoint) {
        height: calc(100vh - 44px - 130px);
    }

    &.is-active {
        pointer-events: auto;
    }

    .c-nav.is-fixed & {
        height: calc(100vh - 48px);
    }

    &__background {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transform: translateY(-100%);
        transition: transform $userInactive * 1.5 $easeOut;
    }

    &.is-active &__background {
        transform: none;
        transition: transform $userActive * 2 $easeOutExpo;
    }

    &__close-btn {
        display: none;

        @media (min-width: $navMobileBreakpoint) {
            display: block;
            position: absolute;
            top: 20px;
            right: 20px;
            opacity: 0;
            transform: translateY(30px);
            transition: transform $userInactive $easeOut,
                opacity $userInactive $easeOut;
            pointer-events: none;
        }
    }

    &.is-active &__close-btn {
        transform: none;
        opacity: 1;
        transition: transform $userActive $easeOut 120ms,
            opacity $userActive $easeOut 120ms;
        pointer-events: auto;
    }

    &__container {
        height: 100%;
        position: relative;
        pointer-events: none;
        opacity: 0;
        transform: translateY(30px);
        transition: transform $userInactive $easeOut,
            opacity $userInactive $easeOut;
        padding: 100px $gutter 60px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @media (min-width: $sm) {
            overflow: auto;
        }
    }

    &.is-active &__container {
        pointer-events: auto;
        transform: none;
        opacity: 1;
        transition: transform $userActive $easeOut 120ms,
            opacity $userActive $easeOut 120ms;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;

        > * {
            flex: 0 0 330px;
            margin-bottom: 50px;
            margin-left: 35px;
            margin-right: 35px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        text-align: center;

        p:nth-last-child(2) {
            padding-bottom: 30px;
        }

        .o-btn {
            margin-top: auto;
        }
    }

    &__title {
        display: block;
        font-size: 1.8rem;
        color: $blue;
        font-weight: 500;

        + * {
            margin-top: 14px;
        }
    }

    &__icon {
        position: relative;
        display: flex;
        justify-content: center;
        color: $grey-light;

        + * {
            margin-top: 22px;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &__btn {
        margin-top: 26px;
    }

    &__book-title {
        text-align: center;

        + * {
            margin-top: 75px;
        }
    }

    &__image {
        position: relative;

        + * {
            margin-top: 30px;
        }

        &:before {
            content: "";
            display: block;
            padding-bottom: ratio(16, 11);
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }
}

@supports (display: grid) {
    .c-group-nav {
        &__list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(290px, 330px));
            grid-gap: 50px 70px;

            > * {
                margin: 0;
            }
        }
    }
}

@supports (object-fit: cover) {
    .c-group-nav {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
