$whiteVariantContrast: $grey-light;

.c-square {
    position: relative;
    max-width: 448px;
    max-height: 448px;
    padding: 0;
    color: #fff;
    background-color: $teal;

    @include theme-color(background-color);

    &:not(.c-square--white):nth-child(2) {
        @include theme-color(background-color, true);
    }

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 20px solid $teal;
            pointer-events: none;
            z-index: 2;

            @include theme-color(border-color);
        }

        &:before {
            content: "";
            position: absolute;
            top: 20px;
            left: 20px;
            bottom: 20px;
            right: 20px;
            border: 1px solid #fff;
        }
    }

    &--white {
        color: $whiteVariantContrast;
        background-color: #fff !important;
    }

    &--no-frame {
        padding: 0;
    }

    &--white &__container:after {
        border-color: #fff;
    }

    &--white &__container:before {
        border-color: $whiteVariantContrast;
        z-index: 10;
    }

    &--no-frame &__container:after {
        display: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding: 40px;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__slide &__content {
        transition: transform 1ms $easeOutQuart;
        transform: translateX(100%);
    }

    &__slide.is-current &__content {
        transition: transform 1100ms $easeOutQuart;
        transform: none;
    }

    &__slide.is-current ~ &__slide:not(.is-previous) &__content {
        transition: transform 1ms $easeOutQuart;
        transform: translateX(100%);
    }

    .is-left &__slide.is-current ~ &__slide:not(.is-previous) &__content {
        transition: transform 1ms $easeOutQuart;
        transform: translateX(-100%);
    }

    &__slide.is-previous &__content {
        transition: transform 1100ms $easeOutQuart;
        transform: translateX(-100%);
    }

    .is-left &__slide.is-previous &__content {
        transition: transform 1100ms $easeOutQuart;
        transform: translateX(100%);
    }

    &--white &__content {
        width: 90%;
    }

    &__label,
    &__title {
        position: relative;
        font-family: $header-font-family;
        font-size: 1.3rem;
        text-transform: uppercase;
        letter-spacing: 0.165em;

        > span {
            display: block;
            overflow: hidden;
            height: 20px;
        }

        > span > span {
            display: block;
        }

        &:after {
            content: "";
            position: absolute;
            width: 30px;
            height: 1px;
            background-color: #fff;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
        }
    }

    &__content &__title {
        margin-bottom: 35px;
    }

    &__label {
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: $xxs) {
            top: 55px;
        }
    }

    &__label span span {
        transition: transform 1ms $easeOutQuart;
        transform: translateY(-20px);
    }

    .is-current &__label span span {
        transition: transform 1100ms $easeOutQuart;
        transform: none;
    }

    .is-current ~ &__slide:not(.is-previous) &__label span span {
        transition: transform 1ms $easeOutQuart;
        transform: translateY(-20px);
    }

    .is-left .is-current ~ &__slide:not(.is-previous) &__label span span {
        transition: transform 1ms $easeOutQuart;
        transform: translateY(20px);
    }

    .is-previous &__label span span {
        transition: transform 1100ms $easeOutQuart;
        transform: translateY(20px);
    }

    .is-left .is-previous &__label span span {
        transition: transform 1100ms $easeOutQuart;
        transform: translateY(-20px);
    }

    &--white &__label:after {
        background-color: $whiteVariantContrast;
    }

    &--white:nth-child(2) &__label:after {
        background-color: $whiteVariantContrast;
    }

    &__logo {
        width: 100%;
        flex: 0 0 auto;

        img,
        svg {
            height: auto;
            width: 100%;
            max-width: 280px;
            margin: 0 auto;
        }

        svg {
            color: #fff;
        }

        + * {
            margin-top: 35px;
        }
    }

    &--white &__logo {
        img,
        svg {
            max-width: none;
        }

        svg {
            color: $whiteVariantContrast;
        }
    }

    &--with-logo &__logo {
        img,
        svg {
            max-width: 245px;
        }
    }

    &__text {
        width: 100%;
        font-family: $font-family-serif;
        font-size: 2rem;
        line-height: 1.4;
        margin-top: auto;

        @media (min-width: $xxs) {
            font-size: 2.8rem;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-top: 0;
        }
    }

    &--with-logo &__text {
        font-size: 1.8rem;
        line-height: 1.4;

        @media (min-width: $xxs) {
            font-size: 2.2rem;
        }
    }

    &__small-text {
        font-family: $header-font-family;
        font-size: 1.3rem;
        margin-bottom: auto;
        width: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-bottom: 0;
        }

        @media (min-width: $xxs) {
            font-size: 1.4rem;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__slide {
        height: 100%;
    }

    &__viewport {
        position: relative;
        overflow: hidden;
        height: 100%;

        + * {
            margin-top: 15px;
        }
    }

    &__track {
        position: relative;
        height: 100%;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--no-frame &__track {
        display: flex;

        > * {
            flex: 1 0 100%;
            position: relative;
            top: auto;
            left: auto;
        }
    }

    &__arrows {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        width: 100%;
        left: 0;
        transform: translateY(-50%);

        @media (min-width: $xs) {
            width: calc(100% + 48px);
            left: -24px;
        }
    }

    &__dots {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        height: 10px;
        opacity: 1;
        transition: opacity $userInactive $easeOut;

        > * + * {
            margin-left: 5px;
        }

        @media (min-width: $xxs) {
            bottom: 55px;
        }
    }

    &__dot {
        display: flex;
        align-items: center;
        position: relative;
        height: 8px;
        width: 8px;
        border: 1px solid #fff;
        border-radius: 8px;
        pointer-events: none;

        &:hover,
        &.is-active {
            background-color: #fff;
        }
    }

    &--no-frame &__dot {
        pointer-events: auto;
    }

    &--white &__dot {
        border: 1px solid $whiteVariantContrast;

        &:hover,
        &.is-active {
            background-color: $whiteVariantContrast;
        }
    }

    &__transition-watch {
        opacity: 0;
        pointer-events: none;
        width: 1px;
        height: 1px;
        background-color: transparent;

        &.is-active {
            transition: transform 1100ms + (175ms * 2);
            transform: translate(10px);
        }

        .is-anim-reduced &.is-active {
            transition-duration: 1ms;
        }
    }

    .o-carousel__slide {
        position: relative;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .o-media-credit:hover {
        .o-media-credit__icon {
            svg {
                color: rgba(0,0,0,.2);
            }
        }
    }
}

@supports (object-fit: cover) {
    .c-square {
        .o-carousel__slide {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
