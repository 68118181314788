//====================
// Typography
//====================

$typegraphyClass: ".typography";

// Font Families
//====================
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$font-family: "CeraGR", $font-family-sans-serif;
$header-font-family: $font-family;
$font-family-serif: "Narziss", $font-family-sans-serif;

// weights
//====================
$font-weight: 400;
$strong-weight: 600;
$header-weight: 400;
$header-bold-weight: 700;

// lineHeight
//====================
$base-font-size: 1.7rem;
$header-line-height: 1.333;
$p-line-height: 1.764;
$small-font-size: 1.4rem;

// spacing - // altered for lineheight space
//====================
$h1Space: 25px; // 60px
$largeSpace: 35px; // 50px
$mediumSpace: 25px; //40px
$smallSpace: 15px; // 30px

// header styles
//====================
$baseHeaderStyles: (
    line-height: $header-line-height,
    color: $header-color,
    font-weight: $header-weight,
    font-family: $header-font-family
);

// each header map can take custom styles, if they are different
// from the base header styles it will be added, e.g. letting spacing in h1
$headerStyles: (
    h1: (
        font-size: 4.8rem,
        font-weight: 300
    ),
    h2: (
        font-size: 3rem,
        font-weight: 300,
        line-height: 1.2666
    ),
    h3: (
        font-size: 2.4rem,
        font-weight: 300,
        color: #{$grey}
    ),
    h4: (
        font-size: 1.7rem,
        font-weight: 500,
        line-height: 1.764
    ),
    h5: (
        font-size: 1.4rem,
        font-weight: 500,
        line-height: 1.714
    )
);

// links
//====================
$link-color: $text-color;
$link-hover: $teal;
$link-decoration: underline;
$link-hover-decoration: underline;
