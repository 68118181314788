.c-popup {
    display: flex;
    padding: 0;
    max-width: 928px;

    .c-newsletter-cta__form > * {
        transform: none;
        transition: none;
        position: relative;
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-color: $grey-light;
        color: #fff;

        svg {
            position: absolute;
            top: 5px;
            left: 5px;
            width: 14px;
            height: 14px;
        }
    }

    &__container {
        display: flex;
        overflow: auto;
        background-color: #fff;
    }

    &__image {
        display: none;
        position: relative;
        flex: 0 0 40%;
        max-width: 360px;
        width: 40%;
        overflow: hidden;

        @media (min-width: $md) {
            display: block;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        padding: 25px;

        @media (min-width: $md) {
            padding: 28px 50px 42px;
        }

        p,
        h4 {
            position: relative;
        }
    }
}

@supports (object-fit: cover) {
    .c-popup {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
