// Grid size
//====================

$col-1: 100 / $columns * 1%;
$col-2: 100 / $columns * 2%;
$col-3: 100 / $columns * 3%;
$col-4: 100 / $columns * 4%;
$col-5: 100 / $columns * 5%;
$col-6: 100 / $columns * 6%;
$col-7: 100 / $columns * 7%;
$col-8: 100 / $columns * 8%;
$col-9: 100 / $columns * 9%;
$col-10: 100 / $columns * 10%;
$col-11: 100 / $columns * 10%;
