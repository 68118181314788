.c-map {
    position: relative;
    margin-bottom: 40px;

    &__map {
        &:after {
            content: "";
            display: block;
            padding-bottom: ratio(1, 1);

            @media (min-width: $sm) {
                padding-bottom: ratio(16, 9);
            }

            @media (min-width: $md) {
                padding-bottom: ratio(930, 470);
            }
        }

        .c-mix-content-aside__item &:after {
            @media (min-width: $md) {
                padding-bottom: ratio(568, 380);
            }
        }

        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--anim &__wrap {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;
    }

    &--anim.scroll-active &__wrap {
        transform: none;
    }

    &--margin {
        margin: $gutter;
    }

    &--decor {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: calc(#{-$col-2} - 45px);
            height: 100%;
            width: 8px;
            background-color: $teal;
            display: none;

            @include theme-color(background-color);

            @media (min-width: $md) {
                display: block;
            }
        }
    }

    &--anim.c-map--decor:after {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;
    }

    &--anim.scroll-active.c-map--decor:after {
        transform: none;
    }

    &__expand-btn {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &__title {
        position: relative;
        padding-bottom: 11px;
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 3rem;
        font-weight: 300;
        line-height: 1.3;
        padding-top: 45px;

        @media (min-width: $sm) {
            padding-top: 180px;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 60px;
            height: 1px;
            transform: translateX(-50%);
            background-color: currentColor;
        }

        + * {
            margin-top: 30px;

            @media (min-width: $sm) {
                margin-top: 50px;
            }
        }
    }

    &__container {
        max-width: 930px;
    }

    &__background-wrap {
        position: relative;
        padding-bottom: 50px;
    }

    &__background-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 660px;
        z-index: -1;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            @media (min-width: $sm) {
                display: none;
            }
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 660px;
        z-index: -1;
        background: #000;
        overflow: hidden;
        display: none;

        @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
            &:not(*:root) {
                display: block;
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
            opacity: 0.7;
            display: none;

            @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
                &:not(*:root) {
                    display: block;
                }
            }
        }

        &:last-child {
            display: block;

            img {
                display: block;
            }
        }

        &--blur {
            &:nth-child(2) {
                z-index: -2;

                img {
                    -webkit-filter: blur(15px);
                    filter: blur(10px);
                }
            }

            &:nth-child(3) {
                z-index: -3;

                img {
                    -webkit-filter: blur(17px);
                    filter: blur(15px);
                }
            }

            &:nth-child(4) {
                z-index: -4;

                img {
                    -webkit-filter: blur(20px);
                    filter: blur(20px);
                }
            }
        }
    }

    &__directions {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: space-between;
        margin-left: -$gutter;
        margin-right: -$gutter;
        overflow: hidden;

        + * {
            margin-top: $gutter;
        }

        @media (min-width: $sm) {
            flex-wrap: nowrap;
            margin-left: 0;
            margin-right: 0;
            padding: 30px 40px;
            background-color: rgba(0, 0, 0, 0.4);

            + * {
                margin-top: 0;
            }
        }

        > * {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            font-size: 1.4rem;
            text-align: center;
            flex: 1 1 50%;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 30px;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 0;
            }

            svg {
                width: 48px;
                height: 48px;
            }

            span {
                margin-top: 5px;
                font-weight: 500;
            }

            span + span {
                font-weight: 400;
            }

            @media (min-width: $sm) {
                flex: 1 1 50%;
                margin-top: 0;
                padding-left: 0;
                padding-right: 0;

                + * {
                    margin-left: 75px;
                }
            }
        }
    }

    &__directions-wrap {
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 3;
        transform: translateY(100%);
    }

    .scroll-active &__directions-wrap {
        transform: none;
    }
}

@supports (object-fit: cover) {
    .c-map {
        &__background {
            img {
                top: -10px;
                left: 0;
                width: 100%;
                height: calc(100% + 20px);
                transform: none;
                object-fit: cover;
            }
        }
    }
}
