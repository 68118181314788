.o-media-credit {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 20;

    &__icon {
        position: relative;
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        transition: background-color $userInactive $easeOut, color $userInactive $easeOut;

        svg {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &:hover &__icon {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.2);
        transition: background-color $userActive $easeOut, color $userActive $easeOut;
    }

    &__text {
        position: absolute;
        top: -40px;
        right: 0;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 1.4rem;
        transform: translate(calc(-100% + 20px), -10px);
        pointer-events: none;
        opacity: 0;
        transition: opacity $userInactive $easeOut, transform $userInactive $easeOut;

        @media (min-width: $lg) {
            left: 50%;
            right: auto;
            transform: translate(-50%, -10px);
        }

        span {
            color: $grey-dark;
            position: relative;
            z-index: 2;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
        }

        &:after {
            z-index: 1;
        }

        &:before {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
        }
    }

    &:hover &__text {
        opacity: 1;
        transition: opacity $userActive $easeOut, transform $userActive $easeOut;
        pointer-events: auto;
        transform: translate(calc(-100% + 20px), -30px);

        @media (min-width: $lg) {
            transform: translate(-50%, -30px);
        }
    }

    .o-carousel &__text {
        bottom: -40px;
        top: auto;
        right: 0;
        left: auto;
        transform: translate(0, 35px);
    }

    .o-carousel &:hover &__text {
        transform: translate(0, 15px);
    }

    &__arrow {
        &:after,
        &:before {
            position: absolute;
            top: 100%;
            right: 5px;

            @media (min-width: $lg) {
                left: 50%;
                right: auto;
            }
        }

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 8px solid #fff;
        }

        &:before {
            content: '';
            width: 12px;
            height: 12px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
            transform: translateY(-8px) rotate(45deg);
        }
    }

    .o-carousel &__arrow:after,
    .o-carousel &__arrow:before {
        top: -8px;
        right: 5px;
        left: auto;
    }

    .o-carousel &__arrow:after {
        border-top: 0;
        border-bottom: 8px solid #fff;
    }

    .o-carousel &__arrow:before {
        transform: translateY(8px) rotate(45deg);
    }
}