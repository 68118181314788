blockquote,
q {
    text-align: center;
    quotes: none;
    font-style: normal;
    font-family: $font-family-serif;
    font-size: 2.6rem;
    line-height: 1.5;

    &:before,
    &:after {
        content: none;
    }
}

blockquote {
    + * {
        margin-top: $largeSpace;
    }
}
