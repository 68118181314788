.c-news {
    .c-introduction__title {
        + * {
            margin-top: 36px;

            &:last-child {
                margin-bottom: 36px;
            }
        }
    }

    &__media {
        + * {
            margin-top: 100px;
        }

        &--center {
            > * {
                margin: 0 auto;
            }
        }

        &--halves {
            max-width: 928px;

            > * {
                > * {
                    max-width: 448px;
                    margin: 0 auto;
                }
            }
        }
    }

    &__image-wrap {
        position: relative;

        &:after {
            content: "";
            display: block;
            padding-bottom: ratio(1140, 538);
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__media--halves &__image-wrap:after {
        padding-bottom: 100%;
    }

    &__content {
        + * {
            margin-top: 100px;
        }
    }
}

@supports (object-fit: cover) {
    .c-news {
        &__image-wrap {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
