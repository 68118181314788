.c-3x-cta {
    position: relative;
    overflow: hidden;
    transform: translateY(100%);

    &:nth-child(1) {
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;
    }

    &:nth-child(1) &__text {
        transition: transform $scrollDuration $easeOutExpo $scrollDuration/5 * 2 +
            $scrollDelay;
    }

    &:nth-child(2) {
        transition: transform $scrollDuration $easeOutExpo $scrollDelay + 150ms;
    }

    &:nth-child(2) &__text {
        transition: transform $scrollDuration $easeOutExpo $scrollDuration/5 * 2 +
            $scrollDelay + 150ms;
    }

    &:nth-child(3) {
        transition: transform $scrollDuration $easeOutExpo $scrollDelay + 400ms;
    }

    &:nth-child(3) &__text {
        transition: transform $scrollDuration $easeOutExpo $scrollDuration/5 * 2 +
            $scrollDelay + 400ms;
    }

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;

        @media (min-width: $sm) {
            padding-bottom: ratio(448, 548);
        }
    }

    &__wrap {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;

        .c-map__background-wrap + & {
            padding-top: 15px;
        }
    }

    &__container {
        margin-top: 40px;

        @media (min-width: $md) {
            margin-top: 80px;
        }
    }

    &__text-wrap {
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        z-index: 5;
        overflow: hidden;
        border: 1px solid #fff;
        transition: bottom $userInactive $easeOut, right $userInactive $easeOut,
            top $userInactive $easeOut, left $userInactive $easeOut;
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 160px;
        transform: translateX(-50%) translateY(250px);
        padding: 15px 15px 16px 15px;
        background-color: $blue;
        color: #fff;

        @media (min-width: $xs) {
            width: 240px;
        }

        @media (min-width: $sm) {
            width: 160px;
        }

        @media (min-width: $lg) {
            width: 240px;
        }

        span {
            display: block;
            position: relative;
            padding-bottom: 11px;
            font-size: 2rem;
            font-weight: 300;
            line-height: 1.2666;

            @media (min-width: $xs) {
                font-size: 2.6rem;
            }

            @media (min-width: $sm) {
                font-size: 2rem;
            }

            @media (min-width: $lg) {
                font-size: 2.6rem;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 60px;
                height: 1px;
                transform: translateX(-50%);
                background-color: currentColor;
                transition: width $userInactive $easeOut;
            }
        }
    }

    &__background {
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        overflow: hidden;
        z-index: 3;
        transition: bottom $userInactive $easeOut, right $userInactive $easeOut,
            top $userInactive $easeOut, left $userInactive $easeOut;

        &-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
            transition: top $userInactive $easeOut, left $userInactive $easeOut,
                width $userInactive $easeOut, height $userInactive $easeOut;
        }
    }

    &__border {
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        overflow: hidden;
        z-index: 1;
        -webkit-filter: blur(5px);
        filter: blur(5px);

        &-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .scroll-active & {
        transform: none;
    }

    .scroll-active &__text {
        transform: translateX(-50%) translateY(-50%);
    }

    &:hover &__text-wrap {
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 40px;
    }

    &:hover &__text span:after {
        width: 30px;
    }

    &:hover &__background {
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 40px;
    }

    &:hover &__background img {
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        width: calc(100% + 80px);
        height: calc(100% + 80px);
    }
}

@supports (object-fit: cover) {
    .c-3x-cta {
        &__background {
            img {
                top: -125px;
                left: 0;
                width: 100%;
                height: calc(100% + 200px + 50px);
                transform: none;
                object-fit: cover;
            }
        }

        &:hover &__background img {
            top: -145px;
            left: 0;
            width: 100%;
            height: calc(100% + 200px + 40px + 50px);
        }

        &__border {
            img {
                top: -100px;
                left: 0;
                width: 100%;
                height: calc(100% + 200px);
                transform: none;
                object-fit: cover;
            }
        }
    }
}
