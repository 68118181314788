.o-close-btn {
    position: relative;
    width: 48px;
    height: 48px;
    color: #fff;
    background-color: $blue;
    transition: color $userInactive $easeOut,
        background-color $userInactive $easeOut;

    &:hover {
        color: $blue;
        background-color: #fff;
        transition: color $userActive $easeOut,
            background-color $userActive $easeOut;
    }

    &:focus {
        outline: 2px solid $yellow;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 48px;
        height: 48px;
        transform: translate(-50%, -50%);
    }
}
