.o-harbour-event {
    position: relative;
    width: 90px;
    height: 90px;
    color: #fff;
    background-color: $yellow;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &__icon {
        position: relative;
        height: 20px;
        width: 100%;
        flex: 0 0 20px;
        margin-top: 5px;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
    }

    span {
        font-size: 1.1rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        margin-top: 7px;
        text-transform: uppercase;
    }

    &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        bottom: 5px;
        right: 5px;
        border: 1px solid #fff;
    }
}
