.o-table {
    > tbody > tr {
        border: 1px solid $grey-lighter;

        &:nth-child(odd) {
            background-color: $off-white;
        }

        > td,
        > th {
            padding: 10px;
        }
    }

    table > tbody > tr > td,
    table > tbody > tr > th {
        padding: 0;
    }

    &__more-info {
        width: 30px;

        &-wrap {
            height: 29px;
            display: flex;
            align-items: center;
        }
    }

    caption {
        margin-bottom: 50px;
    }
}
