.o-radio,
.o-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.3rem;

    &--mid-text {
        font-size: 1.5rem;
    }

    > label {
        cursor: pointer;
        margin-right: 8px;
        text-transform: none;
        font-weight: 400;

        * {
            pointer-events: none;
        }
    }

    a {
        text-decoration: underline;
    }

    label + label,
    label + span {
        margin-top: 0;
        padding: 0;
        width: auto;
        height: auto;
    }

    input {
        width: 0.1px;
        height: 0.1px;
        position: absolute;
        opacity: 0;
        overflow: hidden;
        z-index: -1;

        + span {
            position: relative;
            display: block;
            width: 24px;
            height: 24px;
            border: 1px solid $grey-light;
            max-width: none;
            background-color: transparent;
            transition: border-color $userInactive $easeOut;
        }

        &:focus + span {
            border-color: $blue;
            transition-duration: $userActive;
        }
    }
}

.o-radio {
    input {
        + span {
            border-radius: 50%;

            &:after {
                content: "";
                position: absolute;
                opacity: 0;
                transition: opacity $userActive $easeOut;
                top: 4px;
                left: 4px;
                bottom: 4px;
                right: 4px;
                background-color: $primary;
                border-radius: 50%;
            }
        }

        &:checked + span:after {
            opacity: 1;
        }
    }
}

.o-checkbox {
    input {
        + span {
            position: relative;
            border-radius: 0;
            padding: 8px;
            transition: border-color $userInactive $easeOut;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 16px;
                height: 16px;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity $userInactive $easeOut;
            }

            &:after {
                display: none;
            }
        }

        &:checked + span svg {
            opacity: 1;
            transition-duration: $userActive;
        }
    }
}
