.o-pagination {
    display: flex;
    justify-content: center;

    + * {
        margin-top: 100px;
    }

    > a,
    > span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey-lighter;
        border: 1px solid $grey-lighter;
        color: $grey-dark;
        width: 48px;
        height: 48px;
        margin: 0 8px;
        text-decoration: none;
        font-size: 1.3rem;
        letter-spacing: 0.16em;
        line-height: 1;
        font-weight: 500;
        transition: background-color $userInactive $easeOut,
            border-color $userInactive $easeOut, color $userInactive $easeOut;

        &.is-active {
            background-color: $blue;
            border-color: $blue;
            color: #fff;
        }
    }

    a {
        &:hover {
            color: $blue;
            background-color: transparent;
            border-color: $blue;
            transition: background-color $userActive $easeOut,
                border-color $userActive $easeOut, color $userActive $easeOut;
        }
    }

    svg {
        width: 24px;
        height: 24px;
    }

    & > &__dots,
    & > &__predot,
    & > &__postdot {
        display: none;

        @media (min-width: $sm) {
            display: flex;
        }
    }
}
