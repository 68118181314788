//====================
//	colours
//====================

//	colour pallete
//====================
//need to be bright colour

// Primary
$blue: #162351;
$blue-highlight: #e6e9f0;
$teal: #00a3ad;

// secondary
$yellow: #f1c400;
$dark-teal: #268791;
$red: #ee5959;

// tertiary
$room-gold: #d0af60;
$spa-blue: #01536f;
$spa-blue-light: #4eb4c8;
$eat-red: #ab2229;
$eat-red-light: #db5717;
$wedding-violet: #5167a7;
$wedding-violet-light: #93a5d9;
$meeting-blue: #1d6aa5;
$meeting-blue-light: #3d9fd3;
$see-green: #0c444a;
$see-green-light: #93b430;
$jetty-blue: #5a6986;
$jetty-grey: #818285;
$gate-green: #2b5342;
$gate-gold: #e1af59;
$beach-club-coral: #e76953;
$beach-club-deep-sea: #21274e;
$beach-club-bamboo: #738670;
$beach-club-terracotta: #d4b59e;
$beach-club-shallow-sea: #6e80a9;
$beach-club-jubilee-grey: #7c878e;
$beach-club-off-grey: #c4bfb6;

//greys
$grey: #555;
$grey-dark: #434343;
$grey-light: #9d9d9d;
$grey-lighter: #ddd;
$off-white: #fafafa;
$black: #000;

// named colours
//====================
$primary: $blue;
$error: #d0021b;
$error-bg: #fae5e8;
$success: #b2d002;
$success-bg: #f4f8d9;
$text-color: $grey-dark;
$header-color: $blue;
$body-background-color: #fff;
