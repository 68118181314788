.o-back-btn {
    position: relative;
    margin: 100px 0 170px;

    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 244px;
        height: 128px;
        top: -40px;
        left: -150px;
        background-color: $dark-teal;
        z-index: 0;
        transform: translateY(140px);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;

        @media (min-width: $xs) {
            height: 244px;
        }
    }

    .o-btn {
        z-index: 1;
        transform: translateY(140px);
        transition: transform $scrollDuration $easeOutExpo;
        width: 100%;

        @media (min-width: $xs) {
            width: auto;
        }
    }

    &.scroll-active:after,
    &.scroll-active .o-btn {
        transform: none;
    }
}
