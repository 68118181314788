.o-video {
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: ratio(1140, 538);
    }

    &__player > *,
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__player {
        position: absolute;
        top: 80px;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &__overlay {
        opacity: 1;
        transition: opacity 1400ms $easeOut;
    }

    &.is-open &__overlay {
        pointer-events: none;
        opacity: 0;
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
    }

    &__title {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        color: white;
        text-transform: uppercase;
        font-size: 1.3rem;
        letter-spacing: 2px;

        &:after {
            content: '';
            position: absolute;
            width: 30px;
            border-top: 1px solid white;
            left: 50%;
            bottom: -5px;
            
            transform: translateX(-50%);
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    &--autoplay {
        z-index: 20;

        video {
            position: absolute;
            width: 100%;
            height: auto;
        }
    }

    &__popup-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        pointer-events: none;
        z-index: $zindex-popover;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#000, 0.65);
            opacity: 0;
            transition: opacity $userInactive $easeOut;
        }
    }

    &.is-open &__popup-container {
        pointer-events: auto;

        &:before {
            opacity: 1;
            transition-duration: $userActive;
        }
    }

    &__popup {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 60px);
        // max-width: $max-width;
        max-width: calc(100 * 15 / 10 * 1vh);
        transform: translate(-50%, calc(-50% + 50px));
        transition: transform $userInactive $easeOut,
            opacity $userInactive $easeOut;
        pointer-events: none;
        opacity: 0;

        &:before {
            content: "";
            display: block;
            padding-bottom: ratio(16, 9);
            padding-top: 80px;
        }
    }

    &.is-open &__popup {
        transform: translate(-50%, -50%);
        transition: transform $userActive $easeOut, opacity $userActive $easeOut;
        pointer-events: auto;
        opacity: 1;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: $blue;
        transition: color $userInactive $easeOut,
            background-color $userInactive $easeOut,
            transform $userInactive $easeOut;
        transform: translateY(50px);

        &:hover {
            color: $blue;
            background-color: #fff;
            transition: color $userActive $easeOut,
                background-color $userActive $easeOut,
                transform $userActive $easeOut;
        }
    }

    &.is-open &__close {
        transition: color $userActive $easeOut,
            background-color $userActive $easeOut,
            transform $userActive $easeOut 120ms;
        transform: none;
    }
}

@supports (object-fit: cover) {
    .o-video {
        &__image {
            top: 0;
            left: 0;
            height: 100%;
            transform: none;
            object-fit: cover;
        }
    }
}
