.o-accordion {
    position: relative;
    background-color: transparent;
    text-align: left;
    width: 100%;
    border: 1px solid currentColor;
    color: $grey-light;
    transition: color $userInactive $easeOut;

    &:hover,
    &.is-active {
        color: $blue;
        transition: color $userActive $easeOut;
    }

    &__list {
        padding-top: 20px;
        padding-bottom: 25px;

        > * + * {
            margin-top: 18px;
        }
    }

    &__title {
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-size: 1.6rem;
        color: $grey;
        cursor: pointer;

        &-wrap {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        span {
            display: block;
            padding: 10px 17px;
        }
    }

    &:hover &__title,
    &.is-active &__title {
        color: $blue;
    }

    &__icon {
        position: relative;
        width: 34px;
        height: 34px;
        margin-left: 31px;
        margin-right: 8px;
        color: $grey-light;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: currentColor;
            transform: translate(-50%, -50%);
        }

        &:before {
            width: 11px;
            height: 1px;
        }

        &:after {
            height: 11px;
            width: 1px;
            transition: transform $userInactive $easeOut;
            transform-origin: center;
        }
    }

    &__title:focus &__icon {
        outline: 2px solid $blue;
    }

    &__title:focus:not(.focus-visible) &__icon {
        outline: none;
    }

    &.is-active &__icon:after {
        transform: translate(-50%, -50%) rotateZ(90deg);
        transition-duration: $userActive;
    }

    &.active &__icon {
        transform: rotate(180deg);
    }

    &__container {
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height $userInactive $easeOut;
    }

    &.is-active &__container {
        height: auto;
        transition: height $userActive $easeOut;
    }

    &__content {
        padding: 0 17px 20px;
        color: $text-color;
    }
}
