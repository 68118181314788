//====================
//	Font Families
//====================

@font-face {
    font-family: "Narziss";
    src: url("../fonts/NarzissTextSemibold.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "CeraGR";
    src: url("../fonts/CeraPRO-Regular.otf") format("opentype"),
        local("CeraGR-Regular");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "CeraGR";
    src: local("CeraGR-RegularItalic");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "CeraGR";
    src: local("CeraGR-Bold");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "CeraGR";
    src: local("CeraGR-BoldItalic");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "CeraGR";
    src: url("../fonts/CeraPro-Medium.otf") format("opentype"),
        local("CeraGR-Medium");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "CeraGR";
    src: local("CeraGR-MediumItalic");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "CeraGR";
    src: url("../fonts/Cera Pro Light.otf") format("opentype"),
        local("CeraGR-Thin");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "CeraGR";
    src: local("CeraGR-ThinItalic");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Fascino';
    src: url('../fonts/Fascino-Regular.woff2') format('woff2'),
        url('../fonts/Fascino-Regular.woff') format('woff'),
        url('../fonts/Fascino-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fascino';
    src: url('../fonts/Fascino-Medium.woff2') format('woff2'),
        url('../fonts/Fascino-Medium.woff') format('woff'),
        url('../fonts/Fascino-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
