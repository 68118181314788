.c-content-image {
    position: relative;
    transform: translateY(100%);
    transition: transform $scrollDuration $easeOutExpo;

    &__wrap {
        .o-btn {
            margin-bottom: 20px;
        }
    }

    &__wrap.scroll-active & {
        transform: none;
    }

    &__title,
    &__body {
        overflow: hidden;

        > * {
            transform: translateX(-100%);
            transition: transform $scrollDuration $easeOutExpo;
        }
    }

    &__title + * {
        margin-top: 45px;
    }

    &__body > * {
        transition-delay: $scrollDelay * 2;
    }

    &__wrap.scroll-active &__body > *,
    &__wrap.scroll-active &__title > * {
        transform: none;
    }

    &__wrap .o-btn {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2;
    }

    &__wrap.scroll-active .o-btn {
        transform: none;
    }

    &--decor {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: calc(#{-$col-2} - 45px);
            height: 100%;
            width: 8px;
            background-color: $teal;
            transform: translateY(100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay;

            @include theme-color(background-color);

            @media (min-width: $md) {
                display: block;
            }
        }
    }

    &__wrap.scroll-active &--decor:after {
        transform: none;
    }

    &__image {
        position: relative;
        width: 100%;
        height: auto;

        &:after {
            content: "";
            display: block;
            padding-bottom: ratio(568, 380);
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &--frame &__image {
        border: 1px solid rgba(0, 0, 0, 0.6);
        padding: 14px;

        img {
            top: calc(50% + 14px);
            left: calc(50% + 14px);
            width: calc(100% - 28px);
            height: auto;
        }
    }

    &__expand-btn {
        position: absolute;
        right: 1px;
        bottom: 1px;
    }

    + * {
        margin-top: 35px;
    }
}

@supports (object-fit: contain) {
    .c-content-image {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }

        &--frame &__image {
            img {
                @media (min-width: $sm) {
                    top: 14px;
                    left: 14px;
                    height: calc(100% - 28px);
                    width: calc(100% - 28px);
                    object-fit: contain;
                }
            }
        }
    }
}
