table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th {
        text-align: left;
        font-weight: 400;
        color: $blue;
    }

    [class*="col"] {
        float: inherit;
    }

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: 8px;
                vertical-align: top;
            }
        }
    }

    > thead > tr > th {
        vertical-align: bottom;
    }

    > caption + thead,
    > colgroup + thead,
    > thead:first-child {
        > tr:first-child {
            > th,
            > td {
                border-top: 0;
            }
        }
    }
}
