.o-icon-btn {
    display: inline-block;
    position: relative;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    line-height: 1;
    width: 48px;
    text-decoration: none;

    @media (min-width: $md) {
        width: auto;
        min-width: 180px;
    }

    &:hover {
        text-decoration: none;
        color: #fff;
    }

    span {
        display: none;

        @media (min-width: $md) {
            display: inline;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
    }

    &__wrap {
        display: flex;
        position: relative;
        z-index: 1;

        span {
            padding: 18px 14px 17px;
        }
    }

    &__icons {
        position: relative;
        width: 48px;
        height: 48px;
        margin-left: auto;
        overflow: hidden;
    }

    &__icon,
    &__hover-icon {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    &__icon {
        transition: opacity $userInactive $easeOut;
    }

    &:hover &__icon {
        opacity: 0;
        transition-duration: $userActive;
    }

    &__hover-icon {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: transform $userInactive * 1.5 $easeOut;
    }

    &:hover &__hover-icon {
        transform: none;
        transition: transform $userActive * 2 $easeOutExpo;
    }

    &--expand &__icon,
    &--expand &__hover-icon {
        transition: opacity $userInactive $easeOut,
            transform $userInactive $easeOut;

        svg {
            width: 22px;
            height: 22px;
        }
    }

    &--expand:hover &__icon {
        opacity: 0;
        transform: scale(2);
    }

    &--expand &__hover-icon {
        transform: scale(0);
        opacity: 0;
        transition: opacity $userActive $easeOut, transform $userActive $easeOut;
    }

    &--expand:hover &__hover-icon {
        transform: scale(1);
        opacity: 1;
    }
}
