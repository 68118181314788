$accordionBreakpoint: $md;

.c-email-prefs {
    &__introduction {
        margin-bottom: 75px;
        margin-top: 55px;

        @media (min-width: $accordionBreakpoint) {
            margin-bottom: 60px;
            margin-top: 80px;
        }

        + * {
            margin-top: 42px;
        }

        .typography {
            h1 {
                font-size: 3.6rem;

                @media (min-width: $accordionBreakpoint) {
                    font-size: 4.8rem;
                }
            }

            p {
                font-size: 1.7rem;

                @media (min-width: $accordionBreakpoint) {
                    font-size: 3rem;
                    font-weight: 300;
                }
            }
        }
    }

    &__tab-nav {
        display: none;
        justify-content: center;

        > * {
            margin: 5px;
        }

        @media (min-width: $accordionBreakpoint) {
            display: flex;
        }
    }

    &__tab-btns {
        background-color: $blue;
        border-radius: 10px 10px 0 0;
        color: #fff;
        flex: 1;
        font-size: 1.4rem;
        letter-spacing: 1.85px;
        line-height: 1;
        margin: 0;
        padding: 13px 15px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color $userInactive $easeOut,
            color $userInactive $easeOut, border-color $userInactive $easeOut;

        > * {
            pointer-events: none;
        }

        + * {
            margin-left: 7px;
        }

        &:hover,
        &.is-active {
            background-color: $yellow;
            border-color: $yellow;
            color: $blue;
            text-decoration: none;
            transition: background-color $userActive $easeOut,
                color $userActive $easeOut, border-color $userActive $easeOut;
        }
    }

    &__tab-panel-list {
        padding-bottom: 0;

        @media (min-width: $accordionBreakpoint) {
            padding-top: 0;
        }

        > *+* {
            margin-top: 0;
        }
    }

    .o-accordion {
        background-color: #F2F2F2;
        border: none;

        @media (min-width: $accordionBreakpoint) {
            &__title-container {
                display: none;
            }
        }

        &__title-wrap {
            background-color: $blue;
            color: #FFF;
            font-size: 1.4rem;
            letter-spacing: 1.58px;
            text-transform: uppercase;
            transition: background-color 0.25s $easeOut, color 0.25s $easeOut;
        }

        &__icon {
            color: #FFF;

            &:before {
                height: 2px;
                width: 13px;
            }

            &:after {
                height: 13px;
                width: 2px;
            }
        }

        &__container {
            height: 0;

            @media (min-width: $accordionBreakpoint) {
                display: none;
                height: auto !important;
            }
        }


        &.is-active {
            .o-accordion__title-wrap {
                background-color: $yellow;
                color: $blue;
            }

            .o-accordion__icon {
                color: $blue;
            }

            .o-accordion__container {
                display: block;
            }

        }
    }

    &__intro {
        position: relative;
        padding-bottom: 22px;
        padding-top: 40px;

        @media (min-width: $accordionBreakpoint) {
            padding-left: 75px;
            padding-right: 75px;
        }

        &-content {
            @media (min-width: $accordionBreakpoint) {
                display: flex;
                flex-wrap: wrap;

                > * {
                    flex: 1 0;

                    &:first-child {
                        flex-basis: 100%;
                    }
                }
            }

            h3 {
                font-size: 3rem;
                color: $blue;

                @media (min-width: $accordionBreakpoint) {
                    font-size: 4.8rem;
                }
            }
        }
    }

    &__example {
        font-size: 1.2rem;
        margin-top: 25px;
        padding: 13px 25px;
        width: auto;

        @media (min-width: $accordionBreakpoint) {
            flex-grow: 0;
            min-width: 200px;
        }
    }

    &__separator {
        border-top-color: $blue;
        margin: 20px 0 30px;

        @media (min-width: $accordionBreakpoint) {
            border-top-width: 2px;
        }
    }

    &__select-options {
        background-color: #FFF;
        margin: 40px 0 20px;
        padding: 30px 20px;

        @media (min-width: $accordionBreakpoint) {
            padding: 20px 40px;
        }

        .o-form__list {
            > * {
                flex: 0 0 100%;

                @media (min-width: $accordionBreakpoint) {
                    flex-basis: auto;
                }
            }
        }

        .o-radio {
            margin: 0 15px 20px;

            @media (min-width: $accordionBreakpoint) {
                margin: 0 15px;
            }

            label {
                letter-spacing: 0;

                @media (min-width: $accordionBreakpoint) {
                    font-size: 1.7rem;
                }
            }

            + .o-radio {
                margin-top: 10px;

                @media (min-width: $accordionBreakpoint) {
                    margin: 0 15px;
                }
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__option-list {
        padding-left: 0;
        padding-right: 0;
    }

    &__option {
        @media (min-width: $xxs) {
            display: flex;
        }

        > * {
            + * {
                margin-top: 15px;

                @media (min-width: $xxs) {
                    margin-left: 32px;
                    margin-top: 0;
                }
            }
        }
    }

    &__image {
        position: relative;
        overflow: hidden;

        @media (min-width: $xxs) {
            width: 208px;
        }

        &:before {
            content: "";
            display: block;
            padding-bottom: ratio(208, 140);
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__description {
        label {
            letter-spacing: 0;
        }

        .o-checkbox {
            margin-top: 15px;
        }
    }

    &__save-bar {
        margin-top: 25px;

        @media (min-width: $accordionBreakpoint) {
            flex-basis: 100% !important;

            &-container {
                text-align: left;
            }
        }

        @media (min-width: $xl) {
            flex-basis: auto !important;
            flex-grow: 1 !important;
            margin-top: 0;

            &-container {
                text-align: right;
            }
        }

        .o-btn {
            &:hover:not(:disabled) {
                color: $teal;
            }
        }
    }

    &__cta {
        background-color: #F2F2F2;
        margin-bottom: 50px;
        padding: 50px 0;

        @media (min-width: $accordionBreakpoint) {
            padding-top: 30px;
        }

        .typography {
            h2 {
                padding-bottom: 0;

                &:after {
                    display: none;
                }

                & + * {
                    margin-top: 20px;
                }
            }

            .o-btn {
                font-size: 1.2rem;
                letter-spacing: 1.58px;
            }
        }
    }
}

@supports (object-fit: cover) {
    .c-email-prefs {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}