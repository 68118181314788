.c-eatndrink {
    position: relative;
    margin-top: 73px;
    color: #fff;

    @media (min-width: $sm) {
        padding: 20px;
    }

    .c-listing-cta__list + & {
        margin-top: 35px;
    }

    &:after,
    &:before {
        content: "";
        position: absolute;
        pointer-events: none;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;
    }

    &.scroll-active:before,
    &.scroll-active:after {
        transform: none;
    }

    &:before {
        background-color: $eat-red-light;
        @include theme-color(background-color);
        top: 25%;
        left: 0;
        width: 100%;
        height: 75%;

        @media (min-width: $sm) {
            top: 0;
            height: 100%;
        }
    }

    .o-theme--beach-club &:before {
        background-color: $beach-club-coral;
        background-image: url('/images/beach-club-whirl.svg');
        background-repeat: no-repeat;
        background-position: 100% 120%;
        background-size: 100% 50%;

        @media (min-width: $xl) {
            background-size: 1440px 590px;
        }
    }

    &:after {
        display: none;
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 20px;
        border: 1px solid #fff;

        @media (min-width: $sm) {
            display: block;
        }
    }

    &__container {
        @media (min-width: $md) {
            display: flex;

            > * {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }

    &__container &__content {
        @media (min-width: $md) {
            margin-left: calc(#{$col-1} + #{$gutter});
            flex: 1 1 $col-4;
            flex-basis: calc(#{$col-4} - #{$full-gutter});
        }
    }

    &__container &__carousel {
        @media (min-width: $md) {
            margin-left: calc(#{$col-1} + #{$gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
            flex: 1 1 $col-4;
            flex-basis: calc(#{$col-5} - #{$full-gutter});
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: auto;
        padding: 30px 0;
        z-index: 1;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2;

        @media (min-width: $sm) {
            padding: 50px 30px 30px;
        }

        @media (min-width: $md) {
            padding: 80px 0;
        }
    }

    &.scroll-active &__content {
        transform: none;
    }

    &__carousel {
        position: relative;
        top: 0;
        z-index: 2;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;
        margin-bottom: 30px;

        @media (min-width: $sm) {
            padding: 0 30px;
        }

        @media (min-width: $md) {
            top: -93px;
            margin-bottom: 0;
            padding: 0;

            .o-theme--beach-club & {
                top: -53px;
            }
        }

        .o-carousel {
            max-width: 690px;
            margin: 0 auto;

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;

                @media (min-width: $sm) {
                    padding-bottom: ratio(16, 9);
                }

                @media (min-width: $md) {
                    padding-bottom: 100%;
                }
            }
        }

        .o-carousel__viewport {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .o-icon-btn {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 5;
        }
    }

    &.scroll-active &__carousel {
        transform: none;
    }
}

@supports (display: grid) {
    .c-eatndrink {
        &__container {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            grid-template-areas: "carousel" "content";
            padding: 0 15px;

            @media (min-width: $md) {
                grid-template-columns:
                    minmax(0, 1fr) minmax(0, 4fr) minmax(0, 1fr) minmax(0, 5fr)
                    minmax(0, 1fr);
                grid-template-areas: "... content ... carousel ...";
            }
        }

        &__container &__content {
            grid-area: content;
            margin: 0;
        }

        &__container &__carousel {
            grid-area: carousel;
            margin: 0;

            @media (min-width: $sm) {
                margin: 30px 0 0;
            }

            @media (min-width: $md) {
                margin: 0;
            }
        }
    }
}
