.o-social {
    display: flex;

    a {
        position: relative;
        width: 48px;
        height: 48px;
        border: 1px solid $grey-light;
        background-color: transparent;
        color: $grey-light;
        transition: background-color $userInactive $easeOut,
            color $userInactive $easeOut;

        &:hover {
            background-color: $grey-light;
            color: #fff;
            transition: background-color $userActive $easeOut,
                color $userInactive $easeOut;
        }

        + a {
            margin-left: 16px;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            transform: translate(-50%, -50%);
        }
    }

    &--large a {
        width: 69px;
        height: 69px;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    &--white a {
        border-color: #fff;
        color: #fff;
        background-color: transparent;

        &:hover {
            background-color: #fff;
            color: $blue;
        }
    }

    &--footer a {
        color: #fff;
        border: 0;
        background-color: transparent;

        + a {
            margin-left: 0;
        }

        &:hover {
            background-color: transparent;
            color: $teal;
        }
    }
}
