.o-slider-arrow {
    position: relative;
    width: 48px;
    height: 48px;
    color: $blue;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-color: white;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 48px;
        height: 48px;
        transform: translate(-50%, -50%);
    }

    &--dark {
        color: #fff;

        &:before {
            opacity: 0.6;
            background-color: #000;
        }
    }
}
