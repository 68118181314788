.c-capacity {
    display: flex;
    align-items: center;

    &__icon {
        flex: 0 1 96px;
        max-height: 96px;
        width: 96px;
        height: 96px;

        svg {
            width: 100%;
            height: 100%;
            color: $grey;
        }
    }

    > * + * {
        margin-left: 30px;
    }

    &__text {
        font-size: 1.4rem;
        color: $grey-light;
    }

    &__list-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$gutter;
        width: calc(100% + #{$full-gutter});
        padding-bottom: 25px;

        > * {
            margin-left: $gutter;
            margin-right: $gutter;
            margin-top: $full-gutter;
            flex: 1 1 100%;
            flex-basis: calc(100% - #{$full-gutter});
            width: calc(100% - #{$full-gutter});

            @media (min-width: $sm) {
                flex: 1 1 50%;
                flex-basis: calc(50% - #{$full-gutter});
                width: calc(50% - #{$full-gutter});
            }

            &:first-child {
                margin-top: 0;
            }

            &:nth-child(2) {
                @media (min-width: $sm) {
                    margin-top: 0;
                }
            }
        }
    }

    * + &__list-wrap {
        margin-top: 40px;
    }
}

@supports (display: grid) {
    .c-capacity__list-wrap {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: $full-gutter;
        width: 100%;
        margin-left: 0;

        @media (min-width: $sm) {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        }

        > * {
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            width: 100%;
        }
    }
}
