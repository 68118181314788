@keyframes wave {
    from {
        transform: none;
    }

    to {
        transform: translateX(100%);
    }
}

.o-square-wave {
    position: relative;
    max-width: 448px;
    width: 100%;

    &:before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $red;
    }

    &__wave {
        position: absolute;
        top: 0;
        right: 20px;
        width: 250%;
        height: 100%;
        overflow: hidden;
        color: #000;
        opacity: 0.1;

        &-wrap {
            position: absolute;
            top: 0;
            right: 0;
            width: 310%;
            height: 100%;
            display: flex;
        }

        svg {
            width: 50%;
            height: 100%;
            animation-name: wave;
            animation-duration: 30000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            &:first-child {
                width: calc(50% + 2px);
                position: relative;
                left: 1px;
            }
        }
    }

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__keyline {
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 20px;
        right: 20px;
        transform: scale(1.1);
        transition: transform $scrollDuration $easeOutExpo;

        .scroll-active & {
            transform: none;
        }

        &:first-child:before,
        &:first-child:after,
        &:last-child:before,
        &:last-child:after {
            content: "";
            position: absolute;
            background-color: #fff;
        }

        &:first-child:before,
        &:last-child:before {
            width: 1px;
            height: 100%;
        }

        &:first-child:after,
        &:last-child:after {
            height: 1px;
            width: 100%;
        }

        &:first-child:after,
        &:first-child:before {
            top: 0;
            left: 0;
        }

        &:last-child:after,
        &:last-child:before {
            bottom: 0;
            right: 0;
            z-index: 1;
        }
    }
}
