.c-mix-content {
    padding: 60px 0;

    @media (min-width: $md) {
        padding: 100px 0 50px;
    }

    & + & {
        padding-top: 0;
    }

    + .c-newsletter-cta {
        padding-top: 60px;
    }

    & + &--no-top-content {
        margin-top: -130px !important;
    }

    .c-introduction + &,
    .c-listing-cta__list-halves + & {
        padding-top: 40px;
    }

    &__wrap {
        position: relative;
        z-index: 1;
        padding: 0 $gutter;
        overflow: hidden;

        @media (min-width: $md) {
            padding: 0 calc(#{$col-1} + #{$gutter});
            min-height: 120px;
        }
    }

    & > &__wrap:first-child {
        @media (min-width: $md) {
            min-height: 0;
        }
    }

    &__background {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            right: 15px;
            height: 100%;
            background-color: $off-white;

            @media (min-width: $md) {
                left: calc(#{$col-1} + #{$gutter});
                right: 0;
            }
        }
    }

    &__background &__wrap {
        padding: 35px $full-gutter;

        @media (min-width: $md) {
            padding: 50px calc(#{$col-1} + #{$gutter + 30px}) 20px;
        }
    }

    &__content {
        overflow: hidden;
        padding-bottom: 10px;

        @media (min-width: $md) {
            width: 50%;
        }

        > * {
            transform: translateX(-100%);
            transition: transform $scrollDuration $easeOutExpo;
        }
    }

    &__wrap.scroll-active &__content > * {
        transform: none;
    }

    &__media {
        padding: 0 $gutter;
        position: relative;
        margin-top: 45px;
        display: flex;
        flex-direction: column;

        @media (min-width: $md) {
            margin-top: 75px;
            padding: 0 calc(#{$col-2} + #{$gutter}) 0
                calc(#{$col-1} + #{$gutter});
        }

        + * {
            margin-top: 45px;

            @media (min-width: $md) {
                margin-top: 75px;
            }
        }
    }

    &--single-aside &__media {
        @media (min-width: $sm) {
            flex-direction: row;
            margin-left: -15px;
            width: calc(100% + 30px);
        }

        @media (min-width: $md) {
            flex-direction: column;
            margin-left: 0;
            width: 100%;
        }

        > * {
            @media (min-width: $sm) {
                flex: 1 1 50%;
                flex-basis: calc(50% - 30px);
                margin: 0 15px;
            }

            @media (min-width: $md) {
                flex: 0 0 auto;
                margin: 0;
            }
        }
    }

    &__media + &__background {
        margin-top: 20px;
    }

    &__large-media {
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        max-width: 448px;
        width: 100%;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2.5;
        margin-bottom: 30px;

        @media (min-width: $sm) {
            max-width: none;
        }

        @media (min-width: $md) {
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            transition: transform $scrollDuration $easeOutExpo;
        }

        &:before {
            content: "";
            display: block;
            padding-bottom: 100%;

            @media (min-width: $sm) {
                padding-bottom: ratio(1048, 589);
            }
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }

        .o-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__media.scroll-active &__large-media {
        transform: none;
    }

    &__aside-media {
        order: 1;

        @media (min-width: $md) {
            order: 0;
            position: absolute;
            top: calc(50% - 75px);
            right: $col-1;
            width: 448px;
            transform: translateY(-50%);
            z-index: 1;
            padding-left: 35px;
        }

        @media (min-width: $lg) {
            padding-left: 0;
        }

        &-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: $sm) {
                flex-direction: row;
            }

            @media (min-width: $md) {
                display: block;
            }

            > * {
                width: 100%;
                transform: translateY(100%);
                transition: transform $scrollDuration $easeOutExpo;

                @media (min-width: $md) {
                    transition: transform $scrollDuration $easeOutExpo
                        $scrollDelay * 2;
                }

                &:nth-child(2) {
                    transition: transform $scrollDuration $easeOutExpo
                        $scrollDelay;

                    @media (min-width: $md) {
                        transition: transform $scrollDuration $easeOutExpo
                            $scrollDelay * 3;
                    }
                }

                &:first-child:not(:last-child) {
                    @media (min-width: $sm) {
                        margin-right: 30px;
                    }

                    @media (min-width: $md) {
                        margin-right: initial;
                    }
                }

                &:last-child:not(:first-child) {
                    @media (min-width: $sm) {
                        margin-left: auto;
                    }

                    @media (min-width: $md) {
                        margin-left: initial;
                    }
                }

                + * {
                    margin-top: 30px;

                    @media (min-width: $sm) {
                        margin-left: 30px;
                        margin-top: 0;
                    }

                    @media (min-width: $md) {
                        margin-top: 30px;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    & + &--no-top-content &__aside-media {
        @media (min-width: $md) {
            top: calc(50% - 5px);
        }
    }

    &.scroll-active &__aside-media-wrap > * {
        transform: none;
    }

    &__decoration-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 100%;
        display: none;

        @media (min-width: $md) {
            display: block;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateY(100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay *
                1.5;

            @include theme-color(background-color);
        }
    }

    &__media.scroll-active &__decoration-line:before {
        transform: none;
    }
}

@supports (object-fit: cover) {
    .c-mix-content {
        &__large-media {
            > img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}
