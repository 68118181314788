.l-container {
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
}

.l-page {
    overflow: hidden;

    @media (min-width: $md) {
        overflow: visible;
    }
}

.l-pinch {
    @media (min-width: $md) {
        width: $col-10;
        margin: 0 auto;
    }

    &--medium {
        @media (min-width: $sm) {
            width: $col-9;
            margin: 0 auto;
        }

        @media (min-width: $md) {
            width: $col-9;
        }
    }

    &--large {
        @media (min-width: $sm) {
            width: $col-10;
            margin: 0 auto;
        }

        @media (min-width: $md) {
            width: $col-8;
        }
    }

    &--x-large {
        @media (min-width: $xs) {
            width: $col-10;
            margin: 0 auto;
        }

        @media (min-width: $sm) {
            width: $col-8;
        }

        @media (min-width: $md) {
            width: $col-6;
        }
    }
}

.l-full {
    padding-left: $gutter;
    padding-right: $gutter;
}

.l-internal-columns {
    margin-left: -$gutter;
    width: calc(100% + #{$full-gutter});
}

.l-vcentre,
.l-vend {
    display: flex;
    align-items: center;

    &--right {
        justify-content: flex-end;
    }

    &--centre {
        justify-content: center;
    }
}

.l-self-end {
    align-self: end;
}

.l-align-end {
    display: flex;
    justify-content: flex-end;
}

.l-vend {
    align-items: flex-end;
}

.l-centre {
    display: flex;
    justify-content: center;
}

.l-space-between {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * + * {
        margin-top: 15px;
    }

    @media (min-width: $xs) {
        text-align: left;
        flex-direction: row;
        justify-content: space-between;

        > * + * {
            margin-top: 0;
        }
    }
}

.l-stretch-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.l-grow-item {
    display: flex;

    > * {
        flex: 1 1 100%;
    }
}
