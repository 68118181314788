.c-spangym-cta {
    padding: 100px 0 50px;
    display: flex;
    flex-wrap: wrap;

    > * {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__image,
    &__primary-image {
        position: relative;
        overflow: hidden;
        width: 100%;

        &-wrap {
            @media (min-width: $md) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                transform: translate(-50%, -50%);

                @media (min-width: $md) {
                    height: 100%;
                    max-width: none;
                    width: auto;
                }
            }
        }
    }

    &__primary-image {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;

        @media (min-width: $md) {
            flex: 1 1 $col-4;
            flex-basis: calc(#{$col-4} - #{$full-gutter});
            margin-left: calc(#{$col-1} + #{$gutter});
        }

        &-wrap {
            position: relative;

            @media (min-width: $md) {
                position: absolute;
            }

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;

                @media (min-width: $sm) {
                    padding-bottom: ratio(448, 633);
                }

                @media (min-width: $md) {
                    display: none;
                }
            }
        }
    }

    &.scroll-active &__primary-image {
        transform: none;
    }

    &__viewport {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-bottom: ratio(448, 528);
        z-index: 1;
        display: none;

        @media (min-width: $md) {
            display: block;
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 200%;
            background-color: #fff;
        }

        &:before {
            bottom: 100%;
        }

        &:after {
            top: 100%;
        }
    }

    &__primary-image &__viewport {
        padding-bottom: ratio(448, 588);
        top: 500px;
    }

    &__content {
        overflow: hidden;

        @media (min-width: $md) {
            flex: 1 1 $col-5;
            flex-basis: calc(#{$col-5} - #{$full-gutter});
            margin-right: calc(#{$col-2} + #{$gutter});
        }

        &-wrap {
            transform: translateX(-100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay;
        }
    }

    &.scroll-active &__content-wrap {
        transform: none;
    }

    &__image-list {
        display: none;

        @media (min-width: $md) {
            padding-bottom: 35px;
        }
    }

    &__image {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;

        @media (min-width: $md) {
            margin-top: 35px;
        }

        &:first-child {
            transition-delay: $scrollDelay * 2;
        }

        &:last-child {
            transition-delay: $scrollDelay * 3;
        }

        + * {
            margin-left: 30px;
        }

        &:after {
            content: "";
            display: block;
            padding-bottom: ratio(448, 633);
        }
    }

    &.scroll-active &__image {
        transform: none;
    }
}

@supports (display: grid) {
    .c-spangym-cta {
        display: grid;
        padding-left: $gutter;
        padding-right: $gutter;
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas: "primaryImage" "content";
        grid-gap: 30px;

        @media (min-width: $sm) {
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
            grid-template-areas: "primaryImage images" "content content";
        }

        @media (min-width: $md) {
            grid-template-columns: minmax(0, 4fr) minmax(0, 1fr) minmax(0, 5fr) minmax(
                0,
                2fr
            );
            grid-template-areas:
                "primaryImage ... content ..."
                "primaryImage images images images";
            grid-gap: 10px 30px;
        }

        > * {
            margin: 0;
        }

        &__primary-image {
            grid-area: primaryImage;

            @media (min-width: $md) {
                margin-top: 20px;
                margin-bottom: 100px;
            }
        }

        &__content {
            grid-area: content;
        }

        &__image-list {
            grid-area: images;

            @media (min-width: $sm) {
                display: flex;
            }
        }
    }
}

@supports (object-fit: cover) {
    .c-spangym-cta {
        &__image-wrap img,
        &__primary-image-wrap img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: none;
            object-fit: cover;
        }
    }
}
