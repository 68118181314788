.o-filter-nav {
    &__form {
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;

        &--top {
            border-bottom: 1px solid white;
            padding-bottom: 20px;
        }

        &--bottom {
            margin-top: 20px !important;
        }
    }

    &__btn {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 17px;
        color: #fff;
        display: block;
        font-size: 1.3rem;
        font-weight: 700;
        letter-spacing: 0;
        margin: 5px;
        padding: 6px 18px;
        text-decoration: none;
        text-transform: none;
        transition: background-color $userInactive $easeOut,
            color $userInactive $easeOut;

        @media (min-width: $xs) {
            display: inline-block;
            width: auto;
        }

        &:hover,
        &.is-active {
            background-color: #fff;
            color: $dark-teal;
            text-decoration: none;
            transition: background-color $userActive $easeOut,
            color $userActive $easeOut;
        }
    }

    &__input {
        clip: rect(1px,1px,1px,1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        width: 1px;
    }
}
