.o-carousel {
    position: relative;

    &__viewport {
        position: relative;
        overflow: hidden;
    }

    &--ratio &__viewport {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__track {
        display: flex;

        > * {
            flex: 1 0 100%;
        }
    }

    &--ratio &__track {
        height: 100%;

        > * {
            height: 100%;
            position: relative;
        }
    }

    &--ratio img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
    }

    &__arrow {
        position: absolute;
        top: 50%;
        width: 35%;
        height: 80%;
        z-index: 1;
        transform: translateY(-50%);

        .o-slider-arrow {
            position: absolute;
            top: 50%;
            transition: transform $userInactive * 1.5 $easeOut;
        }

        &--prev {
            left: 0;

            .o-slider-arrow {
                left: 0;
                transform: translate(-100%, -50%);

                @media (min-width: $xs) {
                    transform: translate(10px, -50%);
                }
            }
        }

        &--next {
            right: 0;

            .o-slider-arrow {
                right: 0;
                transform: translate(100%, -50%);

                @media (min-width: $xs) {
                    transform: translate(-10px, -50%);
                }
            }
        }

        &:disabled {
            pointer-events: none;
        }

        &:hover:not(:disabled) .o-slider-arrow {
            @media (min-width: $xs) {
                transform: translate(0, -50%);
                transition: transform $userActive * 2 $easeOutExpo;
            }
        }
    }

    &__dots {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        transition: opacity $userInactive $easeOut;
        z-index: 2;

        @media (min-width: $xxs) {
            bottom: 55px;
        }
    }

    &--dots-left &__dots {
        bottom: 20px;
        left: 14px;
        transform: none;
    }

    &__dot {
        height: 8px;
        width: 8px;
        border: 1px solid #fff;
        border-radius: 50%;

        + * {
            margin-left: 4px;
        }

        &:hover,
        &.is-active {
            background-color: #fff;
        }
    }

    &--grey &__dot {
        border: 1px solid $grey-light;

        &:hover,
        &.is-active {
            background-color: $grey-light;
        }
    }

    &__paging {
        bottom: 10px;
        right: 10px;
        width: 50px;
        height: 30px;
        background-color: rgba($color: #000000, $alpha: 0.8);
        text-align: center;
        position: absolute;
        z-index: 2;
        color: white;
        font-size: 1.5rem;
        line-height: 2;
    }

    &__slide img {
        pointer-events: none;
    }
}

@supports (object-fit: cover) {
    .o-carousel {
        &--ratio img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: none;
            object-fit: cover;
        }
    }
}
