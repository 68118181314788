@mixin css-lock(
    $min-size: 3,
    $max-size: 5,
    $min-width: 1200,
    $max-width: 1920,
    $property: font-size,
    $unit: rem
) {
    #{$property}: #{$min-size}#{$unit};

    $minBreakpointInEms: $min-width / 16;
    $maxBreakpointInEms: $max-width / 16;

    @media (min-width: #{$minBreakpointInEms}em) {
        @if $unit == rem {
            #{$property}: calc(
                #{$min-size}#{$unit} +
                (
                    (#{$max-size} - #{$min-size}) *
                    (
                        (100vw - #{$min-width}px) /
                        ((#{$max-width} - #{$min-width}) / 10)
                    )
                )
            );
        } @else {
            #{$property}: calc(
                #{$min-size}#{$unit} +
                (
                    (#{$max-size} - #{$min-size}) *
                    (
                        (100vw - #{$min-width}px) /
                        (#{$max-width} - #{$min-width})
                    )
                )
            );
        }
    }

    @media (min-width: #{$maxBreakpointInEms}em) {
        #{$property}: #{$max-size}#{$unit};
    }
}

@mixin css-lock2(
    $min1: 100,
    $max1: 200,
    $min2: 100,
    $max2: 200,
    $min-width: 560,
    $max-width: 1200,
    $property1: width,
    $property2: height,
    $unit: px
) {
    #{$property1}: #{$min1}#{$unit};
    #{$property2}: #{$min2}#{$unit};

    $minBreakpointInEms: $min-width / 16;
    $maxBreakpointInEms: $max-width / 16;

    @media (min-width: #{$minBreakpointInEms}em) {
        @if $unit == rem {
            #{$property1}: calc(
                #{$min1}#{$unit} +
                (
                    (#{$max1} - #{$min1}) *
                    (
                        (100vw - #{$min-width}px) /
                        ((#{$max-width} - #{$min-width}) / 10)
                    )
                )
            );
            #{$property2}: calc(
                #{$min2}#{$unit} +
                (
                    (#{$max2} - #{$min2}) *
                    (
                        (100vw - #{$min-width}px) /
                        ((#{$max-width} - #{$min-width}) / 10)
                    )
                )
            );
        } @else {
            #{$property1}: calc(
                #{$min1}#{$unit} +
                (
                    (#{$max1} - #{$min1}) *
                    (
                        (100vw - #{$min-width}px) /
                        (#{$max-width} - #{$min-width})
                    )
                )
            );
            #{$property2}: calc(
                #{$min2}#{$unit} +
                (
                    (#{$max2} - #{$min2}) *
                    (
                        (100vw - #{$min-width}px) /
                        (#{$max-width} - #{$min-width})
                    )
                )
            );
        }
    }

    @media (min-width: #{$maxBreakpointInEms}em) {
        #{$property1}: #{$max1}#{$unit};
        #{$property2}: #{$max2}#{$unit};
    }
}
