// colours
//===============
.h-text {
    &--grey {
        color: $grey !important;
    }

    &--grey-dark {
        color: $grey-dark !important;
    }

    &--grey-light {
        color: $grey-light !important;
    }

    &--blue {
        color: $blue !important;
    }

    &--blue-highlight {
        color: $blue-highlight !important;
    }

    &--teal {
        color: $teal !important;
    }

    &--yellow {
        color: $yellow !important;
    }

    &--dark-teal {
        color: $dark-teal !important;
    }

    &--red {
        color: $red !important;
    }

    &--room-gold {
        color: $room-gold !important;
    }

    &--spa-blue {
        color: $spa-blue !important;
    }

    &--spa-blue-light {
        color: $spa-blue-light !important;
    }

    &--eat-red {
        color: $eat-red !important;
    }

    &--eat-red-light {
        color: $eat-red-light !important;
    }

    &--wedding-violet {
        color: $wedding-violet !important;
    }

    &--wedding-violet-light {
        color: $wedding-violet-light !important;
    }

    &--meeting-blue {
        color: $meeting-blue !important;
    }

    &--meeting-blue-light {
        color: $meeting-blue-light !important;
    }

    &--see-green {
        color: $see-green !important;
    }

    &--see-green-light {
        color: $see-green-light !important;
    }

    &--white {
        color: #fff !important;
    }

    &--black {
        color: $black !important;
    }

    &--truncate {
        @include text-truncate;
    }

    &--nowrap {
        white-space: nowrap !important;
    }

    &--lowercase {
        text-transform: lowercase !important;
    }

    &--uppercase {
        text-transform: uppercase !important;
    }

    &--center {
        text-align: center !important;
    }

    &--right {
        text-align: right !important;
    }

    &--left {
        text-align: left !important;
    }

    &--intro {
        font-size: 3rem !important;
        text-align: center;
        font-weight: 300 !important;
        line-height: 1.6 !important;

        + * {
            margin-top: 125px !important;
        }
    }

    &--caption {
        display: block;
        font-size: 3.4rem !important;
        font-family: $font-family-serif !important;
        color: $grey-light;
        line-height: 1.171 !important;

        @media (min-width: $md) {
            font-size: 5.8rem !important;
        }

        + * {
            margin-top: 32px !important;
        }

        .o-theme--beach-club & {
            letter-spacing: -0.06em;
            font-family: Fascino, 'Times New Roman', Times, serif !important;
            color: $beach-club-coral;
            font-weight: 600;
            line-height: 2 !important;

            @media (min-width: $md) {
                font-size: 4.6rem !important;
            }
        }

        .o-theme--beach-club .h-text--white & {
            color: #fff;
        }
    }

    &--underline {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 60px;
            height: 1px;
            background-color: currentColor;
        }
    }

    &--2-cols {
        columns: 20rem auto;
    }
}

.o-theme--beach-club {
    .c-nav__book {
        .o-book-btn {
            background-color: $beach-club-coral;
        }
    }
    .c-introduction__container {
        .typography, 
        .c-introduction__text {
            h2 {
                &::after {
                    display: none;
                }
            }
        }
    }
}