.c-upcoming-events {
    position: relative;
    color: #fff;

    @media (min-width: $md) {
        min-height: 100vh;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $dark-teal;
        transform: translateY(30vh);
        transition: transform $scrollDuration $easeOutExpo;
    }

    + .c-listing-cta__list {
        padding-top: 100px;
    }

    &__tracker.scroll-active + &:before {
        transform: none;
    }

    &--cta {
        min-height: 0;
    }

    &__background {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        pointer-events: none;
        z-index: 2;
        display: none;
        transform: translateY(30vh);
        transition: transform $scrollDuration $easeOutExpo;

        @media (min-width: $md) {
            display: block;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 20px;
            width: calc(100% - 40px);
            height: 20px;
            background-color: $dark-teal;
        }

        &:before {
            top: 0;
            border-bottom: 1px solid #fff;
        }

        &:after {
            bottom: 0;
            border-top: 1px solid #fff;
        }

        div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:after {
                content: "";
                position: absolute;
                top: 20px;
                left: 20px;
                bottom: 20px;
                right: 20px;
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
            }
        }
    }

    &__tracker.scroll-active + & &__background {
        transform: none;
    }

    &--cta &__background {
        position: absolute;
        height: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        padding: 20px 0;

        > * {
            margin-left: 15px;
            margin-right: 15px;
        }

        @media (min-width: $md) {
            flex-direction: row;
            flex-wrap: wrap;
            padding: 20px 5px;
            margin-top: -100vh;
        }
    }

    &__container &__title {
        @media (min-width: $md) {
            flex: 1 1 $col-5;
            flex-basis: calc(#{$col-10} - #{$full-gutter});
            margin-left: calc(#{$col-1} + #{$gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
        }
    }

    &__container &__list {
        @media (min-width: $md) {
            flex: 1 1 $col-5;
            flex-basis: calc(#{$col-5} - #{$full-gutter});
            margin-left: calc(#{$col-1} + #{$gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
        }
    }

    &__container &__aside {
        @media (min-width: $md) {
            flex: 1 1 $col-4;
            flex-basis: calc(#{$col-4} - #{$full-gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
        }
    }

    &--2col &__container &__list {
        @media (min-width: $md) {
            flex: 1 1 $col-5;
            flex-basis: calc(#{$col-10} - #{$full-gutter});
            margin-left: calc(#{$col-1} + #{$gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
        }
    }

    &--cta &__container {
        margin-top: 0;
    }

    &__title {
        margin-top: 22px;
        padding-bottom: 20px;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;

        @media (min-width: $md) {
            margin-top: 100px;
            padding-bottom: 48px;
        }

        .h2,
        .h1 {
            position: relative;
            color: #fff;
        }
    }

    &__tracker.scroll-active + & &__title {
        transform: none;
    }

    &__list {
        z-index: 1;
        padding-bottom: 32px;

        @media (min-width: $md) {
            padding-bottom: 60px;
        }
    }

    &__track {
        display: flex;

        @media (min-width: $md) {
            display: block;
        }

        > * {
            flex: 1 0 100%;
            padding-left: 4px;
            padding-right: 4px;

            @media (min-width: $md) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &--2col &__track {
        @media (min-width: $md) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            width: calc(100% + 30px);

            > * {
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 30px;
                flex: 1 1 50%;
                flex-basis: calc(50% - 30px);
            }
        }
    }

    &--2col &__list--noslider &__track {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        width: calc(100% + 30px);

        > * {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 30px;
            flex: 1 1 100%;
            flex-basis: calc(100% - 30px);

            @media (min-width: $sm) {
                flex: 0 1 50%;
                flex-basis: calc(50% - 30px);
            }
        }
    }

    &__aside {
        position: relative;
        padding-bottom: 20px;
        text-align: center;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 3;

        @media (min-width: $md) {
            padding-top: 54px;
            padding-bottom: 325px;
            text-align: left;
        }

        &-wrap {
            position: sticky;
            top: 20%;
        }
    }

    &__tracker.scroll-active + & &__aside {
        transform: none;
    }

    &__item {
        position: relative;
        padding-top: 20px;

        + * {
            @media (min-width: $md) {
                margin-top: 40px;
            }
        }

        &-title {
            color: #fff;

            + * {
                margin-top: 2px;
            }
        }
    }

    &--2col &__item + * {
        margin-top: 0;
    }

    &--2col &__item--featured {
        @media (min-width: $md) {
            display: flex;
            align-items: flex-start;
        }
    }

    &--2col &__item--featured {
        @media (min-width: $md) {
            flex: 1 1 100%;
        }
    }

    &__label {
        position: absolute;
        top: 0;
        left: 20px;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay;
    }

    &__item.scroll-active &__label {
        transform: none;
    }

    &__item.scroll-active ~ &__item &__label {
        @media (max-width: $sm-max) {
            transform: none;
        }
    }

    &__viewport {
        @media (min-width: $sm) {
            width: 85%;
            margin: 0 auto;
        }

        @media (min-width: $md) {
            width: auto;
            margin: 0;
        }
    }

    &__list--noslider &__viewport {
        width: 100%;
        margin: 0;
    }

    &__image {
        position: relative;
        overflow: hidden;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;
        pointer-events: none;

        + * {
            margin-top: 35px;
        }

        &-wrap {
            position: absolute;
            top: -20px;
            left: -20px;
            bottom: -20px;
            right: -20px;
        }

        &:after {
            content: "";
            display: block;
            padding-bottom: ratio(21, 11);

            @media (min-width: $md) {
                padding-bottom: ratio(568, 380);
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__item.scroll-active &__image {
        transform: none;
    }

    &__item.scroll-active ~ &__item &__image {
        @media (max-width: $sm-max) {
            transform: none;
        }
    }

    &__item--featured &__image {
        @media (min-width: $md) {
            flex: 1 1 60%;
            flex-basis: calc(60% - 30px);
            width: calc(60% - 30px);
            margin-right: 30px;

            &:after {
                padding-bottom: 100%;
            }
        }
    }

    &__item--featured &__description {
        @media (min-width: $md) {
            flex: 1 1 40%;
            flex-basis: calc(40% - 30px);
            width: calc(40% - 30px);
            margin-left: 30px;
            margin-top: 20px;
        }
    }

    &__description {
        text-align: center;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2;

        @media (min-width: $md) {
            text-align: left;
        }

        .o-list__icon {
            color: #fff;
        }

        .o-list + .o-btn {
            margin-top: 32px;

            @media (min-width: $md) {
                margin-top: 12px;
            }
        }

        .o-list {
            display: none;

            @media (min-width: $md) {
                display: block;
                margin-left: -8px;
            }
        }

        .o-list__item span {
            white-space: nowrap;
        }
    }

    &__item.scroll-active &__description {
        transform: none;
    }

    &__item.scroll-active ~ &__item &__description {
        @media (max-width: $sm-max) {
            transform: none;
        }
    }

    &__subtitle {
        position: relative;
        font-size: 1.3rem;
        letter-spacing: 0.165em;
        text-transform: uppercase;
        font-weight: 300;

        + * {
            margin-top: 40px;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -9px;
            left: 50%;
            width: 30px;
            height: 1px;
            background-color: currentColor;
            transform: translateX(-50%);

            @media (min-width: $md) {
                left: 0;
                transform: none;
            }
        }
    }

    &__dots {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        z-index: 2;
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2;

        @media (min-width: $md) {
            display: none;
        }
    }

    &__tracker.scroll-active + & &__dots {
        transform: none;
    }

    &__dot {
        height: 8px;
        width: 8px;
        border: 1px solid #fff;
        border-radius: 50%;

        + * {
            margin-left: 4px;
        }

        &:hover,
        &.is-active {
            background-color: #fff;
        }
    }

    &__filter {
        width: 100%;
        max-width: 328px;
        text-align: left;
        margin: 35px auto 0;
    }

    &__btn-filter {
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 35px auto 0;

        input {
            position: absolute;
            height: 1px;
            width: 1px;
            overflow: hidden;
            clip: rect(1px, 1px, 1px, 1px);
        }

        &,
        div {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: $xs) {
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
            }
        }

        div {
            width: 100%;

            @media (min-width: $xs) {
                width: auto;
            }
        }
    }

    &__filter-btn {
        display: block;
        text-decoration: none;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 17px;
        padding: 6px 18px;
        font-size: 1.3rem;
        font-weight: 700;
        margin: 5px;
        transition: background-color $userInactive $easeOut,
            color $userInactive $easeOut;
        width: 100%;

        @media (min-width: $xs) {
            width: auto;
        }

        &:hover,
        &.is-active {
            background-color: #fff;
            color: $dark-teal;
            text-decoration: none;
            transition: background-color $userActive $easeOut,
                color $userActive $easeOut;
        }
    }

    &__view-all-btn {
        margin-top: 40px;
        text-align: center;
    }

    &__view-all-btn > * {
        transition: transform $scrollDuration $easeOutExpo $scrollDelay * 2, opacity $scrollDuration $easeOutExpo $scrollDelay * 2;
        transform: translateY(200px);
        opacity: 0;
    }

    &__view-all-btn.scroll-active > * {
        opacity: 1;
        transform: translateY(0);
    }
}

@supports (object-fit: cover) {
    .c-upcoming-events {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}

@supports (display: grid) {
    .c-upcoming-events {
        &__container {
            display: grid!important;
            grid-template-columns: minmax(0, 1fr);
            grid-template-areas:
                "title"
                "list"
                "aside";
            grid-gap: 20px;
            padding: 20px 15px;

            @media (min-width: $md) {
                grid-template-columns:
                    minmax(0, 1fr) minmax(0, 5fr) minmax(0, 1fr) minmax(0, 4fr)
                    minmax(0, 1fr);
                grid-template-areas:
                    "... title title title ..."
                    "... list ... aside ...";
                padding: 20px;
            }

            > * {
                margin-left: 0;
                margin-right: 0;
            }
        }

        &__message {
            font-size: 1.3em;
            text-align: center;
        }

        &--2col &__container {
            display: grid!important;
            grid-template-columns: minmax(0, 1fr);
            grid-template-areas:
                "title"
                "list";

            > * {
                margin-left: 0;
                margin-right: 0;
            }

            @media (min-width: $md) {
                grid-template-columns: minmax(0, 1fr) minmax(0, 10fr) minmax(
                    0,
                    1fr
                );
                grid-template-areas:
                    "... title ..."
                    "... list ...";
            }
        }

        &__title {
            grid-area: title;
        }

        &__list {
            grid-area: list;
        }

        &--2col &__track {
            @media (min-width: $md) {
                display: grid!important;
                grid-gap: 30px;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                width: 100%;
                margin: 0;

                > * {
                    margin: 0;
                }
            }
        }

        &__container &__list,
        &--2col &__container &__list {
            margin-left: 0;
            margin-right: 0;
        }

        &__container &__title,
        &--2col &__container &__title {
            margin-left: 0;
            margin-right: 0;
        }

        &__aside {
            grid-area: aside;
        }

        &__container &__aside {
            margin-left: 0;
            margin-right: 0;
        }

        &--2col &__item--featured {
            @media (min-width: $md) {
                grid-column: span 2;
            }
        }
    }
}
