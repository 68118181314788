/**
 * <div class="o-hamburger">
 *   <div><span></span></div>
 * </div>
**/

.o-hamburger {
    position: relative;
    width: 60px;
    height: 60px;

    > * {
        pointer-events: none;
    }

    &,
    &:hover,
    &:focus,
    &:active {
        border: 0;
    }

    &:focus {
        outline: 2px solid #fff;
    }

    div {
        position: absolute;
        height: 18px;
        width: 30px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    div:before,
    div:after,
    div span:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: currentColor;
        left: 0;
        transition: transform $userInactive $easeOut,
            opacity $userInactive $easeOut,
            background-color $userInactive $easeOut;
    }

    div:before {
        top: 0;
    }

    div:after {
        bottom: 0;
    }

    div span:before {
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
    }

    &.is-active div:before,
    &.is-active div:after,
    &.is-active div span:before {
        transition: transform $userActive $easeOut, opacity $userActive $easeOut,
            background-color $userActive $easeOut;
    }

    &.is-active div:before {
        transform: translateY(9px) rotate(45deg);
    }

    &.is-active div span:before {
        opacity: 0;
        transform: translateY(-50%) scaleX(0.1);
    }

    &.is-active div:after {
        transform: translateY(-8px) rotate(-45deg);
    }
}
