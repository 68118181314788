.c-other-locations {
  margin: 50px 0;
  position: relative;

  &:before {
    background-color: $red;
    bottom: 0;
    content: '';
    height: 40%;
    position: absolute;
    width: 100%;

    @media (min-width: $sm) {
      height: 43%;
    }
  }

  // header
  &__title {
    .h2 {
      text-align: center;
      font-size: 5rem!important;
      color: #162351;
      font-weight: 200;

      &:after {
        display: none;
      }

      + * {
        margin-top: 10px !important;
      }
    }
  }

  &__strap {
      margin-bottom: 50px;
      text-align: center;
      p {
        font-size: 1.7em;
        color: #162351;
        font-weight: 100;
      }
  }
  // end of header

  // slider
  &__list {
    margin: 0 100px;

    @media (min-width: $xl) {
      margin: 0 50px;
    }
  }

  &__slide {
    padding: 15px;

    @media (min-width: $sm) {
      flex: 1 0 50%;
    }

    @media (min-width: $lg) {
      flex: 1 0 25%;
    }

    a {
      text-decoration: none;
    }

    &-img {
      display: block;
      margin: 0 auto;
      position: relative;
      padding-bottom: 100%;
      width: 100%;

      img {
        bottom: 0;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &-caption {
      color: #fff;
      display: block;
      font-size: 2.1rem;
      font-weight: 300;
      margin-top: 25px;
      position: relative;
      text-align: center;

      &:after {
        background-color: #fff;
        content: "";
        height: 1px;
        left: 50%;
        margin-top: 5px;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        width: 40px;
      }
    }
  }

  &__arrow {
    height: 200px;
    opacity: 1;
    top: 33%;
    transition: opacity $userActive $easeOut;
    width: 48px;

    @media (min-width: $sm) {
      top: 30%;
    }

    @media (min-width: $md) {
      top: 33%;
    }

    @media (min-width: $lg) {
      top: 25%;
    }

    &:disabled {
      opacity: 0.25;
    }

    .o-slider-arrow {
      transition: none;
    }

    &--prev {
      transform: translate(-130%, -50%);

      .o-slider-arrow {
        @media (min-width: $md) {
          transform: none;
        }
      }
    }

    &--next {
      transform: translate(130%, -50%);

      .o-slider-arrow {
        @media (min-width: $md) {
          transform: none;
        }
      }
    }

    &:hover:not(:disabled) .o-slider-arrow {
      @media (min-width: $md) {
        transform: translate(0, 0);
      }
    }
  }
// end of slider

  &__footer {
    text-align: center;
  }

  &__btn {
    display: inline-block;
    margin: 50px auto;
  }
}