.c-listing-cta-label {
    background-color: #fff;
    text-align: center;
    padding: 14px 26px;

    @media (min-width: $md) {
        padding: 21px 24px;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 1px;
        background-color: $grey-light;
    }

    &__icon {
        display: flex;
        justify-content: center;
        color: $grey-light;
        width: 24px;
        height: 24px;
        position: relative;
        left: 50%;

        + * {
            margin-top: 8px;

            @media (min-width: $md) {
                margin-top: 12px;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            left: 0;
            width: 32px;
            height: 32px;
            transform: translate(-50%, -50%);

            @media (min-width: $md) {
                width: 48px;
                height: 48px;
            }
        }
    }

    &__title {
        display: block;
        color: $teal;
        font-family: $font-family-serif;
        font-size: 1.4rem;
        line-height: 1.54;

        @media (min-width: $md) {
            font-size: 2.6rem;
        }
    }

    &__subtitle {
        display: block;
        font-size: 0.9rem;
        font-weight: 500;
        text-transform: uppercase;

        @media (min-width: $md) {
            font-size: 1.3rem;
        }
    }
}
