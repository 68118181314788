:root {
    --theme: #{$teal};
    --theme-secondary: #{$teal};
}

.o-theme {
    &--rooms {
        --theme: #{$room-gold};
        --theme-secondary: #{$room-gold};
    }

    &--spa {
        --theme: #{$spa-blue};
        --theme-secondary: #{$spa-blue-light};
    }

    &--eat {
        --theme: #{$eat-red};
        --theme-secondary: #{$eat-red-light};
    }

    &--wedding {
        --theme: #{$wedding-violet};
        --theme-secondary: #{$wedding-violet-light};
    }

    &--meeting {
        --theme: #{$meeting-blue};
        --theme-secondary: #{$meeting-blue-light};
    }

    &--see {
        --theme: #{$see-green};
        --theme-secondary: #{$see-green-light};
    }
}
