.c-gift-cta,
.c-newsletter-cta {
    padding-top: 70px;
    padding-bottom: 70px;
    text-align: center;

    @media (min-width: $sm) {
        text-align: left;
    }

    @media (min-width: $md) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .c-listing-cta__list-halves + & {
        padding-top: 50px;
    }

    .o-square-wave__background,
    .o-square-wave__image,
    .o-square-wave__wave {
        transform: translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;
    }

    .o-square-wave__keyline {
        transform: scale(1.1) translateY(100%);
        transition: transform $scrollDuration $easeOutExpo;
    }

    &.scroll-active .o-square-wave__background,
    &.scroll-active .o-square-wave__keyline,
    &.scroll-active .o-square-wave__wave {
        transform: none;
    }

    .o-square-wave__image {
        position: absolute;
        top: -20px;
        left: 50%;
        width: 155%;
        height: 100%;
        transform: translate(-50%, 100%);

        img {
            width: 100%;
        }

    }

    .o-square-wave__video {
        transform: translate(0, 100%);
        transition-delay: $scrollDelay;
    }

    &.scroll-active .o-square-wave__image {
        transform: translate(-50%, 0);
    }

    &.scroll-active .o-square-wave__video  {
        transform: translate(0, 0);
    }

    &__square {
        padding-left: 25px;
        padding-right: 25px;
        align-self: flex-start;
        display: flex;
        justify-content: center;

        @media (min-width: $sm) {
            justify-content: flex-start;
        }

        @media (min-width: $md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__content,
    &__form {
        overflow: hidden;

        > * {
            transform: translateX(-100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay;
        }

        .h2,
        h2 {
            &:after {
                left: 50%;
                transform: translateX(-50%);

                @media (min-width: $sm) {
                    left: 0;
                    transform: none;
                }
            }
        }
    }

    &.scroll-active &__content > *,
    &.scroll-active &__form > * {
        transform: none;
    }

    // This is when global variables override local settings e.g. Christmas campaign
    &--global {
        .o-square-wave {
            &__keyline {
                z-index: 5;
            }

            &__background {
                background-color: transparent;
            }
        }

        .c-gift-cta__content {
            .h-text--caption {
                color: #BD9B60 !important;
                font-size: 4.6rem !important;
            }
        }
    }
}
