.o-read-more {
    &__btn {
        text-transform: uppercase;
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: 0.165em;
        color: $grey;
        transition: color $userInactive $easeOut;

        &-wrap {
            display: flex;
            align-items: center;
        }
    }

    &.is-active &__btn,
    &__btn:hover {
        color: $blue;
        transition-duration: $userActive;
    }

    &__icon {
        margin-left: 10px;
        transition: transform $userInactive $easeOut;

        svg {
            width: 48px;
            height: 48px;
            position: relative;
            top: 0;
            left: -12px;
        }
    }

    &__btn:focus &__icon {
        outline: 2px solid $blue;
    }

    &__btn:focus:not(.focus-visible) &__icon {
        outline: none;
    }

    &.is-active &__icon {
        transform: rotateX(180deg);
        transition-duration: $userActive;
    }

    &__container {
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height $userInactive $easeOut;
    }

    &.is-active &__container {
        height: auto;
        transition: height $userActive $easeOut;
    }

    &__content {
        padding-top: 15px;
    }
}
