.c-introduction {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--first {
        padding-top: 35px;

        @media (min-width: $md) {
            padding-top: 55px;
        }
    }

    + .c-listing-cta__list-halves .c-listing-cta--thin:first-child,
    + .c-introduction {
        @media (max-width: $xs-max) {
            padding-top: 0;
        }
    }

    &--last {
        padding-bottom: 50px;

        @media (min-width: $md) {
            padding-bottom: 100px;
        }

        & + .c-gallery-video {
            margin-top: 0px;
        }
    }

    &__container {
        padding-left: $gutter;
        padding-right: $gutter;
        text-align: center;
        transform: translate3d(0, 100%, 0);
        transition: transform $scrollDuration $easeOutExpo;
    }

    &.scroll-active &__container {
        transform: none;
    }

    &__title {
        color: $blue;
        font-size: 3.6rem;
        font-weight: 300;

        @media (min-width: $md) {
            font-size: 4.8rem;
        }
    }

    .o-theme--beach-club &__title {
        letter-spacing: -0.06em;
        font-family: Fascino, 'Times New Roman', Times, serif !important;
        color: $beach-club-coral;
        font-weight: 600;
        line-height: 2 !important;

        @media (min-width: $md) {
            font-size: 4.6rem !important;
        }
    }

    &__text {
        font-size: 2.4rem;
        line-height: 1.6;
        font-weight: 300;
    }

    &__read-more {
        margin-top: 30px;
        text-align: center;
        transform: translate3d(0, 200px, 0);
        transition: transform $scrollDuration $easeOutExpo;

        > button {
            padding-left: 30px;
        }
    }

    &__read-more.scroll-active {
        transform: none;
    }

    &__read-more-content-wrap {
        @media (min-width: $xs) {
            padding-top: 35px;
            margin-bottom: 30px;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 100vw;
            background-color: $off-white; 
            z-index: 0;
        }
    }

    &__read-more-content {
        text-align: left;
        padding: 15px;
        z-index: 1;
        position: relative;

        @media (min-width: $xs) {
            padding: 60px 0;
        }
    }

    &__icons {
        margin-top: 50px;
        text-align: left;
    }

    &__buttons {
        margin-top: 40px;

        @media (min-width: $xs) {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        > * {
            flex: 1 1 50%;
            width: 100%;

            @media (min-width: $xs) {
                width: auto;
            }

            + * {
                margin-top: 10px;

                @media (min-width: $xs) {
                    margin-top: 0;
                    margin-left: 10px;
                }
            }
        }

        @media (min-width: $sm) {
            display: inline-block;
        }
    }

    &__stars {
        margin-top: 30px;

        > svg {
            width: 20px;
            height: 20px;
            color: $blue;
        }

        svg + svg {
            margin-left: 3px;
        }

        + * {
            margin-top: 40px;
        }
    }

    &__booking-details {
        text-align: left;
        width: auto;
        display: inline-block;
        margin: 0 auto;
    }
}
