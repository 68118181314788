.o-btn {
    position: relative;
    display: inline-block;
    min-width: 180px;
    padding: 17px 25px 16px;
    font-size: 1.3rem;
    letter-spacing: 0.16em;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: $teal;
    border: 1px solid $teal;
    transition: background-color $userInactive $easeOut,
        color $userInactive $easeOut, border-color $userInactive $easeOut;
    border-radius: 0;
    overflow: hidden;
    width: 100%;

    @media (min-width: $sm) {
        width: auto;
    }

    > * {
        pointer-events: none;
    }

    &:hover {
        transition: background-color $userActive $easeOut,
            color $userActive $easeOut, border-color $userActive $easeOut;
        text-decoration: none;
        background-color: transparent;
        color: #fff;
    }

    &:focus {
        outline: 2px solid $yellow;
    }

    #{$typegraphyClass} & {
        margin-top: $mediumSpace;
    }

    span {
        opacity: 1;
        transition: opacity $userInactive $easeOut;
    }

    &:hover span {
        opacity: 0;
        transition-duration: $userActive;
    }

    &:disabled {
        border: none;
        background-color: #999;

        &:hover {
            color: #FFF;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $teal;
        transform: translateX(-100%);
        transition: transform $userInactive * 2 $easeOut;

        svg {
            height: 56px;
            width: 56px;
        }
    }

    &:hover &__icon {
        transform: none;
        transition: transform $userActive * 2 $easeOutExpo;
    }

    &--sm {
        min-width: 130px;
        padding: 12px 10px 11px;
    }

    &--down &__icon {
        transform: translateY(-100%);
    }

    &--left &__icon {
        transform: translateX(100%);
    }

    &--blue {
        background-color: $blue;
        border-color: $blue;
    }

    &--blue &__icon {
        color: $blue;
    }

    &--blue-ghost {
        background-color: transparent;
        border-color: $blue;
        color: $blue;

        &:hover {
            background-color: $blue;
            color: #fff;
        }
    }

    &--white-ghost {
        background-color: transparent;
        border-color: #fff;
    }

    &--white-ghost &__icon {
        color: #fff;
    }

    #{$typegraphyClass} &__wrap,
    &__wrap {
        display: block;
        margin-left: -7px;

        @media (min-width: $sm) {
            display: flex;
            flex-wrap: wrap;
            margin-left: -8px;
        }

        > * {
            flex: 0 0 auto;
            width: 100%;
            margin: 15px 0 0;

            @media (min-width: $sm) {
                width: auto;
                margin: 0 8px;
            }
        }
    }
}
