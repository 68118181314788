@mixin ratio($width, $height) {
    padding-bottom: ratio($width, $height);
}

@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin theme-color($rule, $isSecondary: false) {
    .o-theme--blue & {
        @if ($isSecondary) {
            #{$rule}: $teal;
        } @else {
            #{$rule}: $blue;
        }
    }

    .o-theme--only-blue & {
        #{$rule}: $blue;
    }

    .o-theme--teal & {
        @if ($isSecondary) {
            #{$rule}: $blue;
        } @else {
            #{$rule}: $teal;
        }
    }

    .o-theme--rooms & {
        #{$rule}: $room-gold;
    }

    .o-theme--spa & {
        @if ($isSecondary) {
            #{$rule}: $spa-blue-light;
        } @else {
            #{$rule}: $spa-blue;
        }
    }

    .o-theme--spa.o-theme--secondary & {
        @if ($isSecondary) {
            #{$rule}: $spa-blue;
        } @else {
            #{$rule}: $spa-blue-light;
        }
    }

    .o-theme--eat & {
        @if ($isSecondary) {
            #{$rule}: $eat-red-light;
        } @else {
            #{$rule}: $eat-red;
        }
    }

    .o-theme--eat.o-theme--secondary & {
        @if ($isSecondary) {
            #{$rule}: $eat-red;
        } @else {
            #{$rule}: $eat-red-light;
        }
    }

    .o-theme--wedding & {
        @if ($isSecondary) {
            #{$rule}: $wedding-violet-light;
        } @else {
            #{$rule}: $wedding-violet;
        }
    }

    .o-theme--wedding.o-theme--secondary & {
        @if ($isSecondary) {
            #{$rule}: $wedding-violet;
        } @else {
            #{$rule}: $wedding-violet-light;
        }
    }

    .o-theme--meeting & {
        @if ($isSecondary) {
            #{$rule}: $meeting-blue-light;
        } @else {
            #{$rule}: $meeting-blue;
        }
    }

    .o-theme--meeting.o-theme--secondary & {
        @if ($isSecondary) {
            #{$rule}: $meeting-blue;
        } @else {
            #{$rule}: $meeting-blue-light;
        }
    }

    .o-theme--see & {
        @if ($isSecondary) {
            #{$rule}: $see-green-light;
        } @else {
            #{$rule}: $see-green;
        }
    }

    .o-theme--see.o-theme--secondary & {
        @if ($isSecondary) {
            #{$rule}: $see-green;
        } @else {
            #{$rule}: $see-green-light;
        }
    }

    .o-theme--jetty & {
        @if ($isSecondary) {
            #{$rule}: $jetty-grey;
        } @else {
            #{$rule}: $jetty-blue;
        }
    }

    .o-theme--jetty.o-theme--secondary & {
        @if ($isSecondary) {
            #{$rule}: $jetty-blue;
        } @else {
            #{$rule}: $jetty-grey;
        }
    }

    .o-theme--gate-green & {
        @if ($isSecondary) {
            #{$rule}: $gate-gold;
        } @else {
            #{$rule}: $gate-green;
        }
    }

    .o-theme--gate-green.o-theme--secondary & {
        @if ($isSecondary) {
            #{$rule}: $gate-green;
        } @else {
            #{$rule}: $gate-gold;
        }
    }

    .o-theme--beach-club & {
        @if ($isSecondary) {
            #{$rule}: $beach-club-shallow-sea;
        } @else {
            #{$rule}: $beach-club-coral;
        }
    }
}
