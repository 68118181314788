//====================
// Typography
//====================

// Headings
//===============

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p {
    display: block;
    margin-top: 0;
    font-weight: $font-weight;
    line-height: $p-line-height;
    font-size: $base-font-size;
}

#{$typegraphyClass} h1 + *,
.h1 + * {
    margin-top: $h1Space;
}

#{$typegraphyClass} h2 + *,
.h2 + * {
    margin-top: 45px;
}

#{$typegraphyClass} h3,
#{$typegraphyClass} h4,
#{$typegraphyClass} h5,
.h3,
.h4,
.h5 {
    + * {
        margin-top: $mediumSpace;
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + .h1,
    + .h2,
    + .h3,
    + .h4,
    + .h5 {
        margin-top: $smallSpace;
    }
}

#{$typegraphyClass} p + * {
    margin-top: $mediumSpace;
}

#{$typegraphyClass} p {
    + p,
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + .h1,
    + .h2,
    + .h3,
    + .h4,
    + .h5 {
        margin-top: $largeSpace;
    }
}

#{$typegraphyClass} h1,
#{$typegraphyClass} h2,
#{$typegraphyClass} h3,
#{$typegraphyClass} h4,
#{$typegraphyClass} h5,
#{$typegraphyClass} h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: $header-color;
    font-family: $header-font-family;
    font-weight: $header-weight;
    line-height: $header-line-height;

    strong {
        font-weight: $header-bold-weight;
    }
}

$headerKeys: map-keys($headerStyles);

@each $header in $headerKeys {
    $headerStyle: map-get($headerStyles, $header);
    $styleKeys: map-keys($headerStyle);

    #{$typegraphyClass} #{$header},
    .#{$header} {
        @each $rule in $styleKeys {
            $value: map-get($headerStyle, $rule);

            $baseStyleValue: map-get($baseHeaderStyles, $rule);

            @if $baseStyleValue != $value {
                @if $rule == "space" {
                    + * {
                        margin-top: $value;
                    }
                }

                @elseif $rule == 'base-size' {
                    font-size: $value;
                }

                @elseif $rule == 'sm-size' {
                    @media (min-width: $sm) {
                        font-size: $value;
                    }
                }

                @elseif $rule == 'md-size' {
                    @media (min-width: $md) {
                        font-size: $value;
                    }
                } @else {
                    #{$rule}: $value;
                }
            }
        }
    }
}

#{$typegraphyClass} h2,
.h2 {
    position: relative;
    padding-bottom: 11px;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 1px;
        background-color: currentColor;
    }
}

.h-text--center #{$typegraphyClass} h2:after,
#{$typegraphyClass} .h-text--center h2:after,
#{$typegraphyClass}.h-text--center h2:after,
.h-text--center .h2:after {
    left: 50%;
    transform: translateX(-50%);
}

// .o-theme--beach-club {
//     #{$typegraphyClass} h1,
//     #{$typegraphyClass} h2,
//     #{$typegraphyClass} h3,
//     #{$typegraphyClass} h4,
//     #{$typegraphyClass} h5,
//     #{$typegraphyClass} h6,
//     .h1,
//     .h2,
//     .h3,
//     .h4,
//     .h5,
//     .h6 {
//         font-family: Fascino, 'Times New Roman', Times, serif;
//     }
// }

// inline elements
//===============
table,
pre {
    line-height: $p-line-height;

    + * {
        margin-top: $largeSpace;
    }
}

strong,
b {
    font-weight: $strong-weight;
}

#{$typegraphyClass} small,
small {
    font-size: $small-font-size;
    line-height: 1.714;
}

hr + h2,
hr + .o-table {
    margin-top: 40px;
}
