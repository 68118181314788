.c-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    overflow: hidden;
    z-index: $zindex-nav + 5;
    background-color: #fff;
    height: 130px;

    @media (min-width: $md) {
        z-index: 45;
    }

    > * {
        flex: 1 1 0;
    }

    &__logo {
        display: flex;
        justify-content: center;
        transform: translate3d(0, -70px, 0);
        transition: transform $scrollDuration * 0.9 $easeOutExpo;
    }

    &.scroll-active &__logo {
        transform: none;
    }

    &__group-nav-btn {
        display: none;

        @media (min-width: $navMobileBreakpoint) {
            display: block;
        }
    }

    &__links {
        display: none;
        justify-content: flex-end;

        @media (min-width: $navMobileBreakpoint) {
            display: flex;
        }

        a {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 1.4rem;
            padding-left: 24px;
            padding-right: 24px;
            color: $grey-light;
            white-space: nowrap;
            text-decoration: none;
            text-transform: uppercase;

            svg {
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }

            + a {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 2px;
                    height: 1em;
                    background-color: $grey-light;
                    transform: translateY(-50%);
                }
            }

            &:hover {
                color: $blue;
            }

            &:last-child {
                padding-right: 0;
                padding-left: 32px;
            }
        }
    }

    &__group-nav-btn,
    &__links {
        transform: translate3d(0, -70px, 0);
        transition: transform $scrollDuration * 0.9 $easeOutExpo $scrollDelay *
            2;
    }

    &.scroll-active &__group-nav-btn,
    &.scroll-active &__links {
        transform: none;
    }
}
