.c-nav {
    position: relative;
    z-index: $zindex-nav + 20;
    height: 48px * 2;

    @media ($navMobileBreakpoint) {
        z-index: $zindex-nav;
    }

    &.no-breadcrumbs {
        height: 48px;
    }

    &__container {
        height: 48px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        z-index: $zindex-fixed;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $blue;
            z-index: 1;
        }

        &:after {
            content: "";
            position: fixed;
            top: 48px;
            left: 0;
            width: 100%;
            height: calc(100vh - 48px);
            background-color: #fff;
            z-index: 2;
            pointer-events: none;
            transform: translateY(100%);
            transition: transform $userInactive * 2 $easeInExpo;
        }
    }

    &.is-fixed &__container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }

    &.is-active &__container:after {
        transform: none;
        transition: transform $userActive * 2 $easeOutExpo;
    }

    &__list,
    &__book,
    &__number {
        z-index: 2;
    }

    &__mobile-btn {
        position: fixed;
        bottom: 40px;
        right: 15px;
        width: 68px;
        height: 68px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.7);
        background-color: $blue;
        color: #fff;
        z-index: $zindex-popover;
        border-radius: 50%;

        @media (min-width: $sm) {
            right: 30px;
        }

        @media (min-width: $navMobileBreakpoint) {
            display: none;
        }

        &:after {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
        }

        &:focus {
            outline: none;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        }

        &:focus:after {
            outline: 2px solid #fff;
        }

        &:focus:not(.focus-visible):after {
            outline: none;
        }
    }

    &__tab-nav {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        transform: translateY(-100%);
        transition: transform $userInactive * 2 $easeInExpo;
        pointer-events: none;

        @media (min-width: $navMobileBreakpoint) {
            display: none;
        }
    }

    &.is-active &__tab-nav {
        transform: none;
        transition: transform $userActive * 2 $easeOutExpo;
        pointer-events: auto;

        @media (min-width: $navMobileBreakpoint) {
            display: none;
        }
    }

    &__tab-btn {
        position: relative;
        background-color: $blue;
        color: #fff;
        flex: 1 1 50%;
        text-transform: uppercase;
        line-height: 1;
        font-size: 1.3rem;
        letter-spacing: 0.16em;
        padding: 18px 16px 17px;
        font-weight: 500;
        transition: background-color $userInactive $easeOut $userInactive * 2,
            color $userInactive $easeOut $userInactive * 2;

        &.is-active,
        &:hover {
            background-color: #fff;
            color: $blue;
        }

        &:after {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            outline: 2px solid currentColor;
            opacity: 0;
            transition: opacity $userInactive $easeOut;
        }

        &:focus:after {
            opacity: 1;
            transition-duration: $userActive;
        }

        &:focus:not(.focus-visible):after {
            opacity: 0;
        }
    }

    &.is-active &__tab-btn {
        transition: background-color $userInactive $easeOut,
            color $userInactive $easeOut;

        &.is-active,
        &:hover {
            transition: background-color $userActive $easeOut,
                color $userActive $easeOut;
        }
    }

    &__tab {
        position: fixed;
        top: 48px;
        left: 0;
        width: 100%;
        height: calc(100vh - 48px);
        z-index: 3;
        pointer-events: none;

        @media (min-width: $navMobileBreakpoint) {
            display: flex;
            position: relative;
            top: 0;
            height: auto;
            z-index: 2;
            pointer-events: auto;
            flex: 1 1 100%;
            flex-basis: calc(100% - 140px);
            width: calc(100% - 140px);
        }
    }

    &.is-fixed &__tab {
        @media (min-width: $navMobileBreakpoint) {
            flex-basis: calc(100% - 290px);
            width: calc(100% - 290px);
        }
    }

    &.is-active &__tab.is-active {
        pointer-events: auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @media (min-width: $sm) {
            overflow: auto;
        }
    }

    &__number {
        flex: 1 1 50%;
        display: flex;
        align-items: center;

        @media (min-width: $navMobileBreakpoint) {
            display: none;
            flex: 0 0 150px;
            width: 150px;
            overflow: hidden;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            color: #fff;
            text-decoration: none;
            white-space: nowrap;

            @media (min-width: $navMobileBreakpoint) {
                transform: translateX(100%);
                transition: transform 650ms $easeOutExpo;
            }

            &:hover {
                text-decoration: underline;
            }

            svg {
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }
        }
    }

    &.is-fixed &__number {
        flex: 0 0 50%;
        order: 1;

        @media (min-width: $navMobileBreakpoint) {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 500;
            letter-spacing: 0.16em;
            margin-left: 30px;
            flex: 0 0 150px;
            width: 150px;
        }

        &:empty {
            display: none;
        }
    }

    &.is-fixed &__number.is-active a {
        @media (min-width: $navMobileBreakpoint) {
            transform: none;
        }
    }

    &__mobile-items {
        padding: 20px;

        @media (min-width: $navMobileBreakpoint) {
            display: none;
        }

        > * {
            overflow: hidden;

            > * {
                transform: translateY(48px);
                opacity: 0;
                transition: opacity $userInactive $easeOut,
                    transform $userInactive $easeOut;
            }
        }
    }

    &__tab.is-active &__mobile-items > * {
        > * {
            transform: none;
            opacity: 1;
            transition: transform $userActive * 1.5 $easeOutExpo $userActive,
                opacity $userActive $easeOut;
        }

        &:nth-child(2) > * {
            transition: transform $userActive * 1.5 $easeOutExpo $userActive +
                45ms,
                opacity $userActive $easeOut $userActive + 45ms;
        }
    }

    &__mobile-number {
        background-color: $off-white;
        text-align: center;
        color: $blue;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1;
        padding: 18px 16px;

        a {
            color: $grey;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: $blue;
            }
        }

        &-wrap {
            margin-top: 10px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        @media (min-width: $navMobileBreakpoint) {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 48px;
            width: 0;
            flex: 1 1 auto;
        }

        li + li a {
            border-top: 1px solid $grey-light;

            @media (min-width: $navMobileBreakpoint) {
                border-top: 0;
            }
        }

        a {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: 500;
            text-decoration: none;
            letter-spacing: 0.16em;
            padding: 11px 0;

            @media (min-width: $navMobileBreakpoint) {
                color: #fff;
                padding: 0 19px;
                white-space: nowrap;
            }

            &:after {
                @media (min-width: $navMobileBreakpoint) {
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    width: 30px;
                    height: 1px;
                    background-color: currentColor;
                    transform: translateX(-50%) scaleX(0);
                    opacity: 0;
                    transition: opacity $userInactive $easeOut,
                        transform $userInactive $easeOut;
                }
            }

            &:hover:after,
            &:focus:after,
            &.is-active:after {
                opacity: 1;
                transition: opacity $userActive $easeOut,
                    transform $userActive $easeOut;
                transform: translateX(-50%) scaleX(1);
            }

            &:focus {
                color: $teal;
                outline: none;

                @media (min-width: $navMobileBreakpoint) {
                    color: #fff;
                }
            }

            &:focus:not(.focus-visible):after {
                opacity: 0;
                color: $grey;
            }
        }

        li:first-child a:after {
            display: none;
        }

        li {
            overflow: hidden;

            @media (min-width: $navMobileBreakpoint) {
                overflow: visible;
            }

            a {
                transform: translateY(49px);
                transition: transform $userInactive $easeOut;

                @media (min-width: $navMobileBreakpoint) {
                    transform: none;
                }
            }
        }

        svg {
            width: 22px;
            height: 22px;
            stroke: $text-color;
            fill: transparent;
            transition: fill $userInactive $easeOut;

            @media (min-width: $navMobileBreakpoint) {
                stroke: #fff;
            }
        }

        a:hover svg,
        a.is-active svg {
            fill: $text-color;
            transition-duration: $userActive;

            @media (min-width: $navMobileBreakpoint) {
                fill: #fff;
            }
        }

        li:first-child span {
            height: 22px;
        }
    }

    &.is-fixed &__list {
        @media (min-width: $navMobileBreakpoint) {
            justify-content: flex-start;
        }
    }

    &.is-fixed.no-left-anim &__list {
        @media (min-width: $navMobileBreakpoint) {
            justify-content: center;
        }
    }

    &__tab.is-active &__list li {
        $initialDelay: 60ms;

        a {
            transform: none;
        }

        @for $i from 1 through 12 {
            $delay: $initialDelay + 45ms * $i;

            &:nth-last-child(#{$i}) a {
                transition: transform $userActive * 1.5 $easeOutExpo $delay;
            }
        }
    }

    &__book {
        flex: 1 1 50%;
        order: 2;

        @media (min-width: $navMobileBreakpoint) {
            z-index: 4;
            flex: 0 0 110px;
            width: 110px;
            margin-left: 30px;
        }
    }

    &__container-spacer,
    &__breadcrumbs:after {
        display: none;
        flex-grow: 0;
        flex-shrink: 0;
        width: 110px + 30px;

        @media (min-width: unit(1400 / 16, em)) {
            display: block;
        }
    }

    &.is-fixed:not(.no-left-anim) &__container-spacer,
    &.is-fixed:not(.no-left-anim) &__breadcrumbs:after {
        // width: 110px + 56px + 150px + 30px;
        width: 0;
    }

    &__group-nav {
        @media (min-width: $navMobileBreakpoint) {
            position: absolute;
            top: 48px;
            left: 0;
            width: 100%;
            height: calc(100vh - 48px);
            pointer-events: none;
            z-index: 3;
        }
    }

    &__overflow {
        position: relative;
        z-index: 2;
        height: 100%;

        &-list {
            a {
                padding: 10px 19px;
                min-width: 220px;
                display: block;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 1.3rem;
                font-weight: 500;
                letter-spacing: 0.16em;
                color: $text-color;

                &:hover {
                    color: $primary;
                }

                &:after {
                    display: none;
                }
            }

            li + li {
                border-top: 1px solid $primary;
            }
        }

        &-container {
            position: absolute;
            top: 100%;
            right: -1px;
            background-color: #fff;
            border-left: 1px solid $primary;
            border-right: 1px solid $primary;
            backface-visibility: hidden;
            transform: translate3d(0, 0, 0);
            height: 0;
            will-change: height;
            overflow: hidden;
            transition: height $userInactive $easeOut;

            > *:last-child {
                border-bottom: 1px solid $primary;
            }

            &.is-open {
                height: auto;
                transition: height $userActive $easeOut;
            }
        }
    }

    &__dropdown-btn {
        display: flex;
        align-items: center;
        color: #fff;
        background-color: $primary;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 0.16em;
        padding-left: 19px;
        height: 100%;

        &:hover,
        &:focus,
        &.is-active {
            color: $primary;
            background-color: #fff;
        }

        svg {
            width: 48px;
            height: 48px;
        }
    }

    &__breadcrumbs {
        display: flex;
        position: relative;
        z-index: 1;
        height: 48px;
        background-color: $off-white;

        &:after {
            content: "";
            display: block;

            @media (min-width: unit(1400 / 16, em)) {
                display: none;
            }
        }

        &-container {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: $max-width;
            padding: 0 3px;
            margin: 0 auto;
            height: 100%;
            overflow: hidden;

            @media (min-width: $navMobileBreakpoint) {
                padding: 0 30px;
            }

            > * {
                transform: translateY(48px);
                transition: transform $userActive $easeOut 250ms;
                display: none;

                @media (min-width: $navMobileBreakpoint) {
                    display: block;
                }

                &:nth-last-child(3),
                &:nth-last-child(4) {
                    display: block;
                }

                &:first-child:nth-last-child(3) {
                    order: 1;

                    @media (min-width: $navMobileBreakpoint) {
                        order: 0;
                    }
                }

                &:first-child:nth-last-child(3) + * {
                    display: block;
                }

                &:first-child:last-child {
                    display: block;
                    padding-left: 15px;

                    @media (min-width: $navMobileBreakpoint) {
                        padding-left: 0;
                    }
                }
            }

            &.is-active > * {
                transform: none;
            }
        }

        a {
            text-decoration: none;
            color: $grey-light;
            font-size: 1.3rem;
            font-weight: 500;
            letter-spacing: 0.16em;
            white-space: nowrap;

            &:focus,
            &:hover {
                color: $primary;
                text-decoration: underline;
            }

            &:last-child {
                @media (min-width: $navMobileBreakpoint) {
                    color: $primary;
                }
            }

            &:nth-last-child(3) {
                color: $primary;

                @media (min-width: $navMobileBreakpoint) {
                    color: $grey-light;
                    &:focus,
                    &:hover {
                        color: $primary;
                        text-decoration: underline;
                    }
                }
            }

            svg {
                width: 22px;
                height: 22px;
                margin-left: 4px;
                fill: transparent;
                stroke: $text-color;
                transition: fill $userInactive $easeOut;
            }

            &:hover svg,
            &:focus svg {
                fill: $text-color;
                transition-duration: $userActive;
            }
        }

        &-spacer {
            position: relative;
            margin: 0 12px;
            transform: rotateY(180deg) translateY(48px);

            @media (min-width: $navMobileBreakpoint) {
                transform: translateY(48px);
            }

            &,
            svg {
                width: 7px;
                height: 12px;
            }

            svg {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &-container.is-active &-spacer {
            transform: rotateY(180deg);

            @media (min-width: $navMobileBreakpoint) {
                transform: none;
            }
        }
    }

    &.is-fixed &__breadcrumbs {
        position: fixed;
        top: 48px;
        left: 0;
        width: 100%;
    }

    &.is-fixed:not(.no-left-anim) &__breadcrumbs-container {
        margin: 0;
        padding: 0 35px;
    }
}
