$showDuration: 850ms;
$hideDuration: $showDuration * 0.45;

.o-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: $zindex-fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;

    @media (min-width: $sm) {
        overflow: auto;
    }

    &__header {
        min-height: 260px;
        width: 100%;
        overflow: hidden;
        background-color: #fff;

        &-wrap {
            color: #fff;
            background-color: $grey;
            background-image: url("../images/tmp-overlay-head.jpg");
            background-size: cover;
            background-position: center;
            text-align: center;
            padding: 56px 15px 85px;
            transform: translateY(-100%);
            transition: transform $hideDuration $easeInExpo;
            height: 260px;
            width: 100vw;
        }

        &.isFixed &-wrap {
            position: fixed;
            top: -50px;
            left: 0;
            width: 100%;
            height: 130px;
            padding: 70px 15px 15px;

            @media (min-width: $sm) {
                height: 180px;
                padding: 90px 15px 35px;
            }
        }
    }

    &.is-active &__header-wrap {
        transform: none;
        transition: transform $showDuration $easeOutExpo;
    }

    .o-close-btn {
        position: fixed;
        top: 25px;
        right: 25px;
        z-index: 1;
        width: 32px;
        height: 32px;

        @media (min-width: $sm) {
            top: 40px;
            right: 40px;
            height: 48px;
            width: 48px;
        }

        svg {
            width: 32px;
            height: 32px;

            @media (min-width: $sm) {
                height: 48px;
                width: 48px;
            }
        }
    }

    &__subtitle {
        position: relative;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.3rem;
        letter-spacing: 0.16em;

        + * {
            margin-top: 45px;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 50%;
            width: 30px;
            height: 1px;
            background-color: currentColor;
            transform: translateX(-50%);
        }
    }

    &__header.isFixed &__subtitle {
        display: none;

        + * {
            margin-top: 0;
        }
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 300;

        @media (min-width: $sm) {
            font-size: 3rem;
        }
    }

    &__main {
        margin-top: -50px;
        padding-top: 50px;
        flex: 1 1 auto;
        background-color: #fff;
        transform: translateY(125%);
        transition: transform $hideDuration $easeInExpo;
        z-index: -1;

        &--basic {
            padding-top: 100px;

            @media (min-width: $md) {
                padding-top: 50px + 100px;
            }

            .l-container {
                padding-bottom: 50px;

                @media (min-width: $md) {
                    padding-bottom: 100px;
                }
            }
        }

        .c-map--margin {
            margin: 0;

            @media (min-width: $md) {
                margin: 15px 15px 60px;
            }
        }
    }

    &.is-active &__main {
        transform: none;
        transition: transform $showDuration $easeOutExpo;
    }

    &__footer {
        position: relative;
        z-index: 10;
        flex: 0 0 88px;

        &,
        &-container,
        &-wrap {
            height: 88px;
        }

        &-wrap {
            position: fixed;
            bottom: -50px;
            left: 0;
            height: 130px;
            width: 100%;
            border-top: 4px solid $yellow;
            background-color: $blue;
            color: #fff;
            transform: translateY(100%);
            transition: transform $hideDuration $easeInExpo;
        }

        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 $gutter;
        }

        .o-btn {
            margin-left: auto;
        }
    }

    &.is-active &__footer-wrap {
        transform: none;
        transition: transform $showDuration $easeOutExpo;
    }

    &.hide-footer &__footer {
        display: none;
    }

    &__contact a {
        color: #fff;
        text-decoration: none;

        &:hover {
            color: $teal;
            text-decoration: none;
        }
    }

    &__basic-layout {
        position: relative;

        @media (min-width: $md) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        > * {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    &__basic-layout &__image {
        margin-bottom: 30px;

        @media (min-width: $md) {
            flex: 1 1 $col-4;
            flex-basis: calc(#{$col-4} - #{$full-gutter});
            margin-left: calc(#{$col-1} + #{$gutter});
            margin-bottom: 0;
        }
    }

    &__basic-layout &__body {
        @media (min-width: $md) {
            flex: 1 1 $col-5;
            flex-basis: calc(#{$col-5} - #{$full-gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
        }

        .o-list--2-cols {
            margin-left: -15px;
            width: calc(100% + 30px);

            > * {
                margin: 5px 15px 0;
            }
        }
    }

    &__basic-layout &__full {
        margin-bottom: 30px;

        @media (min-width: $md) {
            flex: 1 1 100%;
            flex-basis: calc(100% - #{$gutter});
            margin-bottom: 0;
        }
    }

    &__image {
        position: relative;
        display: none;
        position: sticky;
        top: 150px;

        @media (min-width: $md) {
            padding-right: 30px;
            display: block;
        }

        &-wrap {
            max-width: 468px;
            margin: 0 auto;
            overflow: hidden;

            &:before {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }
}

@supports (object-fit: cover) {
    .o-overlay {
        &__image {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}

@supports (display: grid) {
    .o-overlay {
        &__basic-layout {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            grid-template-areas: "full" "image" "body";
            grid-gap: 30px;
            padding: 0 $gutter;

            @media (min-width: $md) {
                grid-template-columns:
                    minmax(0, 1fr) minmax(0, 4fr) minmax(0, 5fr)
                    minmax(0, 2fr);
                grid-template-areas: "... image body ...";
            }

            &--full {
                grid-template-areas: "full" "image" "body";

                @media (min-width: $md) {
                    grid-template-areas: "full full full full" "... image body ...";
                }
            }
        }

        &__image {
            grid-area: image;
        }

        &__body {
            grid-area: body;
        }

        &__full {
            grid-area: full;
        }

        &__basic-layout &__image,
        &__basic-layout &__body,
        &__basic-layout &__full {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
