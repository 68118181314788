.c-mix-content-aside {
    padding: 60px 0;

    @media (min-width: $md) {
        padding: 60px 0 100px;
    }

    .c-listing-cta__list + & {
        padding-top: 40px;
    }

    > * {
        margin-left: 15px;
        margin-right: 15px;
    }

    @media (min-width: $md) {
        display: flex;
    }

    &__content {
        @media (min-width: $sm) {
            width: 75%;
        }

        @media (min-width: $md) {
            width: 100%;
            flex: 1 1 $col-5;
            flex-basis: calc(#{$col-5} - #{$full-gutter});
            margin-left: calc(#{$col-1} + #{$gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
        }
    }

    &__aside {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        @media (min-width: $md) {
            padding-bottom: 110px;
            flex: 1 1 $col-4;
            flex-basis: calc(#{$col-4} - #{$full-gutter});
            margin-right: calc(#{$col-1} + #{$gutter});
        }
    }

    &__carousel {
        position: relative;
        position: sticky;
        top: 100px;
        color: #fff;
        text-align: center;
        // max-width: 448px;
        width: 100%;
        
        @media (min-width: $md) {
            margin-top: 80px;
        }

        &-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 35px;
            z-index: 1;

            @media (min-width: $sm) {
                padding: 50px 35px 60px;
            }

            @media (min-width: $sm) {
                padding: 30px 25px;
            }

            @media (min-width: $lg) {
                padding: 50px 35px 60px;
            }
        }

        &:before,
        &:after,
        &-content {
            transform: translateY(420px);
            transition: transform $scrollDuration $easeOutExpo;
        }
    }

    &__square {
        position: relative;
        position: sticky;
        top: 100px;
        color: #fff;
        margin-top: 80px;
        text-align: center;
        max-width: 448px;
        width: 100%;

        &:before {
            content: "";
            position: relative;
            display: block;
            padding-bottom: 100%;
            background-color: $blue;
            z-index: 1;
        }

        &:after {
            content: "";
            position: absolute;
            top: 20px;
            left: 20px;
            bottom: 20px;
            right: 20px;
            border: 1px solid #fff;
            pointer-events: none;
            z-index: 2;
        }

        &-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 35px;
            z-index: 1;

            @media (min-width: $sm) {
                padding: 50px 35px 60px;
            }

            @media (min-width: $sm) {
                padding: 30px 25px;
            }

            @media (min-width: $lg) {
                padding: 50px 35px 60px;
            }
        }

        &:before,
        &:after,
        &-content {
            transform: translateY(420px);
            transition: transform $scrollDuration $easeOutExpo;
        }
    }

    &.scroll-active &__square:before,
    &.scroll-active &__square:after,
    &.scroll-active &__square-content {
        transform: none;
    }

    &__background {
        position: absolute;
        top: -70px;
        left: 50%;
        width: 100vw;
        height: calc(100% + 30px);
        pointer-events: none;

        @media (max-width: $xs-max) {
            transform: translateX(-50%) !important;
        }

        @media (min-width: $sm) {
            width: 510px;
            height: 510px;
        }

        @media (min-width: $md) {
            width: 610px;
            height: 610px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $yellow;
            transform: translateY(100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay;
        }

        &-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            transform: translateY(100%);
            transition: transform $scrollDuration $easeOutExpo $scrollDelay;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: #000;
            opacity: 0.2;
        }
    }

    &.scroll-active &__background:before,
    &.scroll-active &__background-wrap {
        transform: none;
    }

    &__title {
        &,
        > * {
            position: relative;
            font-weight: 500;
            font-size: 1.3rem;
            letter-spacing: 0.165em;
            text-transform: uppercase;
        }

        + * {
            @include css-lock(13, 43, 320, 768, margin-top, px);

            @media (min-width: $md) {
                @include css-lock(13, 43, 1024, 1200, margin-top, px);
            }
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 50%;
            width: 30px;
            height: 1px;
            background-color: currentColor;
            transform: translateX(-50%);
        }
    }

    &__logo {
        width: 100%;
        height: 48px;
        flex: 0 0 48px;

        + * {
            @include css-lock(23, 38, 320, 768, margin-top, px);

            @media (min-width: $md) {
                @include css-lock(23, 38, 1024, 1200, margin-top, px);
            }
        }
    }

    &__body {
        color: currentColor;
        padding-bottom: 15px;

        a {
            color: currentColor;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                color: $teal;
            }
        }
    }

    &__aside .o-btn {
        flex: 0 0 auto;
        margin-top: auto;
        margin-bottom: 30px;

        @media (min-width: $md) {
            margin-bottom: 0;
        }
    }

    &__item {
        overflow: hidden;

        &--no-anim {
            overflow: visible;
        }

        &-wrap {
            transform: translateX(-100%);
            transition: transform $scrollDuration $easeOutExpo;
        }

        &.scroll-active &-wrap {
            transform: none;
        }
    }

    &__item + &__item {
        margin-top: 50px;
    }

    &__item + &__item--caption {
        margin-top: 70px;
    }
}

@supports (display: grid) {
    .c-mix-content-aside {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas: "content" "aside" ;
        padding-left: $gutter;
        padding-right: $gutter;

        @media (min-width: $md) {
            grid-template-columns:
                minmax(0, 1fr) minmax(0, 5fr) minmax(0, 1fr) minmax(0, 4fr)
                minmax(0, 1fr);
            &.c-mix-content-block {
                grid-template-columns:
                    minmax(0, 1fr) minmax(0, 5fr) minmax(0, 1fr) minmax(0, 5fr);
            }
            grid-template-areas: "... content ... aside ...";
            grid-gap: 30px;
        }

        &__content {
            grid-area: content;
            margin: 0;
        }

        &__aside {
            grid-area: aside;
            margin: 0;
            margin-bottom: $gutter*2;
        }
    }
}
