.c-gallery-video {
    margin-top: 100px;

    + * {
        margin-top: 100px; 
    }

    + .c-eatndrink {
        margin-top: 150px;
    }

    &__container {
        display: grid;
        grid-template-columns: minmax(0,1fr);
        grid-template-areas: 
            "g"
            "i"
            "v";
        grid-gap: 30px;
        padding: 15px 30px;

        @media (min-width: $md) {
            grid-template-columns: minmax(0,3fr) minmax(0,2fr);
            grid-template-areas: 
            "g i"
            "g v";
            padding: 0 30px;
        }
    }

    &__gallery {
        position: relative;
        grid-area: g;
        padding-bottom: ratio(768, 511);
    }
    
    &__image {
        position: relative;
        grid-area: i;
        padding-bottom: ratio(539, 241);
    }
    
    &__video {
        position: relative;
        grid-area: v;
        overflow: hidden;
        
        &:before {
            content: "";
            display: block;
            padding-bottom: ratio(539, 241);
        }
    }

    &__video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & .o-video {
            height: 100%;
        }

        & .o-video__overlay:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            bottom: 10px;
            right: 10px;
            border: 1px solid #fff;
            z-index: 2;
        }

        & .o-video__overlay:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%; 
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            z-index: 1;
        }

        & .o-video__image {
            z-index: 0;
        }

        & .o-video__icon {
            z-index: 3;
        }
    }

    &__image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: none;
            object-fit: cover;
        }
    }

    &__gallery-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__gallery-carousel {
        height: 100%;
    }
}

