.o-list {
    #{$typegraphyClass} & {
        margin-left: 0;

        li:before {
            display: none;
        }
    }

    ul {
        margin-left: 1.5em;
    }

    &__item {
        display: flex;

        span {
            display: block;
            min-height: 30px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
    }

    &--circles &__item {
        min-height: 48px;
    }

    &--icons &__icon {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        color: $grey-light;
        flex: 0 0 48px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &--circles &__icon {
        position: relative;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 13px;
        background-color: $yellow;
        color: #fff;
        margin-top: 9px;
        margin-bottom: 9px;
        flex: 0 0 48px;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 14px;
            height: 14px;
            transform: translate(-50%, -50%);
        }
    }

    &--horizontal-2 &__item,
    &--horizontal-3 &__item {
        justify-content: center;
        margin-bottom: 15px;
    }

    &--horizontal-2,
    &--horizontal-3 {
        &,
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        li {
            flex: 1 1 100%;

            @media (min-width: $sm) {
                flex: 1 1 50%;
            }

            @media (min-width: $md) {
                flex: 1 1 30%;
                max-width: 330px;
            }
        }
    }

    &--horizontal-3 {
        li {
            @media (min-width: $md) {
                flex: 1 1 30%;
            }
        }
    }

    &--inline {
        margin-left: 8px;
        width: calc(100% + 16px);

        &,
        ul,
        li {
            display: inline-block;
        }

        li {
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    &--2-cols {
        display: flex;
        flex-wrap: wrap;

        > * {
            flex: 0 1 25rem;
        }
    }
}
